<template>
  <v-container
    app
    color="transparent"
    class="ma-0 pa-0 subheader"
    :class="'weee-logo'"
  >
    <v-sheet class="sheet-sub mx-auto" color="transparent">
      <div class="sub-object">
        <p class="font-weight-bold soweit-font">
          {{ $t("componentSubDrawer.welcomeText1") }}
        </p>
        <h1 class="display-2 font-weight-bold">
          {{ $t("componentSubDrawer.welcomeText2part1") }}
          <br /><span class="color-primer white-back">{{
            $t("componentSubDrawer.welcomeText2part2")
          }}</span
          ><br />
          {{ $t("componentSubDrawer.welcomeText2part3") }}
        </h1>
        <div class="goto-button">
          <v-btn
            color="primary"
            @click="$vuetify.goTo('#mainMass')"
            rounded
            depressed
            x-large
            >{{ $t("componentSubDrawer.btnToQuantityReport") }}</v-btn
          >
          <cursor-image class="cursor-image" />
        </div>
      </div>
    </v-sheet>
  </v-container>
</template>
<script lang="ts">
import Vue from "vue";
import CursorImage from "@/components/svg/CursorImage.vue";

export default Vue.extend({
  components: {
    CursorImage,
  },
  data: () => {
    return {};
  },
});
</script>
<style scoped>
.tvp-logo {
  background-image: url("../assets/DSZ_Mengenmelder_Banner.jpg");
  background-repeat: no-repeat;
  background-position: right top;
  background-size: contain;
}
.weee-logo {
  background-image: url("../assets/DSZ_Mengenmelder_Banner.jpg");
  background-repeat: no-repeat;
  background-position: right top;
  background-size: contain;
}
.subheader {
  min-height: 100vh;
  min-width: 100%;
}
.sheet-sub {
  margin-top: 300px;
  max-width: 1600px;
}
.sub-object {
  width: 75%;
  margin-left: 12.5%;
}
.soweit-font {
  font-size: 1.75rem;
}
.goto-button {
  margin-top: 100px;
  width: 350px;
}
.goto-button >>> .v-size--x-large {
  width: 280px !important;
  height: 62px !important;
}
.goto-button >>> span.v-btn__content {
  color: black;
  text-transform: none;
  font-weight: bold;
}
.goto-button >>> .v-btn::before {
  background-color: #8fedc2;
}
.cursor-image {
  position: relative;
  margin-left: 220px;
  margin-top: -25%;
  width: 80px;
  height: auto;
}
@media only screen and (min-width: 2000px) {
  .subheader {
    min-height: 80vh;
  }
  .sheet-sub {
    margin-top: 350px !important;
  }
  .tvp-logo {
    background-position: 45% top;
    background-size: 65vw auto !important;
  }
  .weee-logo {
    background-position: 45% top;
    background-size: 65vw auto !important;
  }
}
@media only screen and (min-width: 3000px) {
  .subheader {
    min-height: 60vh;
  }
  .tvp-logo {
    background-position: 55% top;
    background-size: 50vw auto !important;
  }
  .weee-logo {
    background-position: 55% top;
    background-size: 50vw auto !important;
  }
}
@media only screen and (min-width: 5000px) {
  .subheader {
    min-height: 50vh;
  }
  .tvp-logo {
    background-position: 50% top;
    background-size: 40vw auto !important;
  }
  .weee-logo {
    background-position: 50% top;
    background-size: 40vw auto !important;
  }
}
@media only screen and (max-width: 1400px) {
  .white-back {
    background: white;
  }
}
@media only screen and (max-width: 1280px) {
  .display-2 {
    font-size: 2.5rem !important;
  }
  .sheet-sub {
    margin-top: 240px !important;
  }
}
@media only screen and (max-width: 1024px) {
  .display-2 {
    font-size: 2.5rem !important;
  }
  .sheet-sub {
    margin-top: 240px !important;
  }
  @media (max-height: 600px) {
    .sheet-sub {
      margin-top: 150px !important;
    }
  }
}
</style>
