<template>
  <v-app-bar app class="pt-3 mass-menu" flat>
    <div class="menu-group">
      <v-col cols="3" class="pa-0">
        <div class="logo-container"></div>
      </v-col>
      <v-spacer></v-spacer>
      <v-skeleton-loader
        type="button"
        class="menu-skeleton"
        v-if="isLoading"
      ></v-skeleton-loader>
      <v-btn
        @click="$vuetify.goTo('#mainMass')"
        text
        class="menu-buttons-nav"
        v-if="!submittedReport && !successMessage && valid && !isLoading"
      >
        <span class="mr-2 font-weight-bold">{{
          $t("mengenmeldungHeader")
        }}</span>
      </v-btn>
      <span class="small-space" />
      <v-skeleton-loader
        type="button"
        class="menu-skeleton"
        v-if="isLoading"
      ></v-skeleton-loader>
      <v-btn
        @click="$vuetify.goTo('#inquiry')"
        text
        class="menu-buttons-nav"
        v-if="!submittedReport && !successMessage && valid && !isLoading"
      >
        <span class="font-weight-bold text-right">{{
          $t("ContactForm.contact")
        }}</span>
      </v-btn>
      <span class="small-space" />
      <v-select
        v-model="$i18n.locale"
        :items="langs"
        :menu-props="{ bottom: true, offsetY: true }"
        item-value="value"
        item-text="label"
        hide-details
        solo
        dense
        flat
        prepend-icon="$vuetify.icons.language"
        class="menu-buttons-nav language-button"
        v-if="!separateImprint"
      ></v-select>
    </div>
  </v-app-bar>
</template>
<script lang="ts">
import Vue from "vue";
import { mapState } from "vuex";

export default Vue.extend({
  props: {
    successMessage: Boolean,
    submittedReport: Boolean,
    separateImprint: Boolean,
    isLoading: Boolean,
  },
  data: () => {
    return {
      langs: [
        { label: "EN", value: "en" },
        { label: "DE", value: "de" },
      ],
    };
  },
  computed: {
    ...mapState({
      valid: (state: any) => state.customerManagement.valid,
    }),
  },
});
</script>
<style lang="sass">
.logo-container
  background-image: url('../assets/Zentek_Logo_RGB.svg')
  background-repeat: no-repeat
  background-position: left top
  background-size: 80% auto
  width: 209px
  height: 40px
.mass-menu
  background-color: transparent !important
  height: 74px !important
  margin: 0 auto
.v-app-bar--is-scrolled
  background-color: white !important
  border-bottom: thin solid #dedede !important
.small-space
  width: 5%
.menu-buttons-nav
  padding-right: 0 !important
.menu-buttons-nav.theme--light.v-btn:hover::before, .menu-buttons-nav.theme--light.v-btn---active:hover::before, .menu-buttons-nav.theme--light.v-btn--active::before
  opacity: 0 !important
.menu-buttons-nav .v-ripple__container
  display: none !important
.language-button
  max-width: 84px !important
  font-size: 0.875rem !important
  font-weight: bold
.language-button .v-input__control
  min-height: 34px !important
.language-button .v-select__selection--comma
  margin: 3px 0px 3px 0 !important
.language-button .v-input__icon, .language-button .v-icon__component, .language-button .v-icon.v-icon
  font-size: 18px !important
  min-width: 18px !important
  height: 18px !important
  width: 18px !important
.language-button .v-input__prepend-outer
  margin-top: 8px !important
  margin-right: 0 !important
.language-button .v-input__append-inner
  margin-left: 0 !important
  padding-left: 0 !important
.language-button input
  padding-left: 0 !important
.language-button .v-input__append-inner .v-icon
  color: rgba(0, 0, 0, 0.87) !important
.theme--light.v-text-field--solo > .v-input__control > .v-input__slot
  background: transparent !important
.v-toolbar__content
  max-width: 1600px !important
  margin: auto
  padding-left: 0 !important
  padding-right: 0 !important
.menu-group
  width: 75%
  margin: auto
  display: inline-flex
.v-list-item--active .v-list-item__title
  color: rgba(0, 0, 0, 0.87) !important
  font-weight: bold !important
.theme--light.v-list-item--active:hover::before, .theme--light.v-list-item--active::before
  opacity: 0.22 !important
.menu-skeleton .v-skeleton-loader__button
  width: 150px !important
</style>
