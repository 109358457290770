<template>
  <v-app :class="{ 'mobile-view': isMobile }">
    <app-drawer
      :successMessage="successMessage"
      :submittedReport="submittedReport"
      :separateImprint="imprint || impressum"
      :isLoading="isLoading"
      :key="componentKey"
      v-if="!isMobile"
    />
    <sub-drawer
      :reportType="reportType"
      v-if="
        !isMobile && !successMessage && !submittedReport && valid && !isLoading
      "
    />
    <sub-drawer-done
      :successMessage="successMessage"
      :reportType="reportType"
      v-if="!isMobile && valid && submittedReport"
    />
    <v-main>
      <router-view
        :class="{ mobile: isMobile, desktop: !isMobile }"
        :reportType="reportType"
        :customerLinkData="customerLinkData"
        v-if="
          !isMobile &&
          !successMessage &&
          !submittedReport &&
          valid &&
          !isLoading
        "
        @submission-done="successMessage = true"
      ></router-view>
      <mobile-view v-if="isMobile"></mobile-view>
      <contact-form
        v-if="
          !isMobile &&
          !successMessage &&
          !submittedReport &&
          valid &&
          !isLoading
        "
        :manufacturerNumber="manufacturerNumber"
        :customerName="customerName"
        :key="componentKey"
        @open-data-privacy="openDataPrivacy"
        @successfully-send-email="componentKey += 1"
      />
    </v-main>
    <skeleton-loader v-if="isLoading"></skeleton-loader>
    <wrong-page v-if="isWrongPage" />
    <maintenance-page v-if="inMaintenance === true" />
    <privacy-policy-page v-if="privacyPolicy" />
    <copyright-english-page v-if="imprint" />
    <copyright-german-page v-if="impressum" />
    <expired-link
      v-if="!valid && expired && !privacyPolicy && !imprint && !impressum"
    />
    <footer-drawer
      v-if="!isMobile"
      @open-data-privacy="openDataPrivacy"
      @open-copyright-en="openCopyrightEN"
      @open-copyright-de="openCopyrightDE"
      @open-legal-notice="openLegalNotice"
    />
    <v-dialog
      scrollable
      v-model="copyrightAndPrivacyDialog"
      max-width="1600"
      width="75%"
      overlay-color="white"
      overlay-opacity="0.4"
      class="rounded-lg"
    >
      <data-privacy
        v-if="dataPrivacy"
        @close-this="copyrightAndPrivacyDialog = false"
      />
      <copy-right-english
        v-if="copyrightEN"
        @close-this="copyrightAndPrivacyDialog = false"
      />
      <copy-right-german
        v-if="copyrightDE"
        @close-this="copyrightAndPrivacyDialog = false"
      />
      <legal-notice
        v-if="legalNotice"
        @close-this="copyrightAndPrivacyDialog = false"
      />
    </v-dialog>
    <app-update :class="{ mobile: isMobile, desktop: !isMobile }" />
  </v-app>
</template>

<script lang="ts">
import Vue from "vue";
import AppDrawer from "@/components/AppDrawer.vue";
import SubDrawer from "@/components/SubDrawer.vue";
import SubDrawerDone from "@/components/SubDrawerDone.vue";
import FooterDrawer from "@/components/FooterDrawer.vue";
import ContactForm from "@/components/ContactForm.vue";
import AppUpdate from "@/components/AppUpdate.vue";
import DataPrivacy from "@/components/atoms/DataPrivacy.vue";
import CopyRightGerman from "@/components/atoms/CopyRightGerman.vue";
import CopyRightEnglish from "@/components/atoms/CopyRightEnglish.vue";
import LegalNotice from "@/components/atoms/LegalNotice.vue";
import { mapState } from "vuex";
import { dachCountries } from "./helpers";
import MobileView from "./components/MobileView.vue";
import WrongPage from "./components/WrongPage.vue";
import ExpiredLink from "./components/ExpiredLink.vue";
import PrivacyPolicyPage from "./components/PrivacyPolicyPage.vue";
import CopyrightEnglishPage from "./components/CopyRightEnglishPage.vue";
import CopyrightGermanPage from "./components/CopyRightGermanPage.vue";
import MaintenancePage from "./components/MaintenancePage.vue";
import SkeletonLoader from "./components/atoms/SkeletonLoader.vue";

export default Vue.extend({
  name: "App",

  components: {
    AppDrawer,
    SubDrawer,
    SubDrawerDone,
    FooterDrawer,
    ContactForm,
    AppUpdate,
    DataPrivacy,
    LegalNotice,
    CopyRightGerman,
    CopyRightEnglish,
    MobileView,
    WrongPage,
    ExpiredLink,
    PrivacyPolicyPage,
    CopyrightEnglishPage,
    CopyrightGermanPage,
    MaintenancePage,
    SkeletonLoader,
  },

  data: () => {
    return {
      successMessage: false,
      copyrightAndPrivacyDialog: false,
      dataPrivacy: false,
      copyrightEN: false,
      copyrightDE: false,
      legalNotice: false,
      notificationReportId: "",
      customerLinkData: {} as any,
      componentKey: 0,
      privacyPolicy: false,
      imprint: false,
      impressum: false,
      isLoading: false,
    };
  },
  created() {
    const urlArray = window.location.hash.split("/");
    const validHash = ["yearly-forecast", "plan-correction", "yearly-report"];
    if (urlArray[1] === "privacy-policy") {
      this.$i18n.locale = "en";
      this.privacyPolicy = true;
    }
    if (urlArray[1] === "datenschutz") {
      this.$i18n.locale = "de";
      this.privacyPolicy = true;
    }
    if (urlArray[1] === "imprint") {
      this.$i18n.locale = "en";
      this.imprint = true;
    }
    if (urlArray[1] === "impressum") {
      this.impressum = true;
      this.$i18n.locale = "de";
    }
    if (validHash.includes(urlArray[1])) {
      this.isLoading = true;
      this.notificationReportId = urlArray[urlArray.length - 1];
      const hashLocation = window.location.hash as string;
      let urlReportType = 1;
      if (hashLocation.includes("plan-correction")) {
        urlReportType = 2;
      } else if (hashLocation.includes("yearly-report")) {
        urlReportType = 3;
      }
      const request = {
        reportId: this.notificationReportId,
        reportType: urlReportType,
      };
      this.customerLinkData = request;
      /* loading customer information and report detail from database*/
      this.$store
        .dispatch("loadCustomerData", request)
        .then(() => {
          this.isLoading = false;
        })
        .catch(() => {
          this.isLoading = false;
        });
    }
  },
  watch: {
    $route(to, from) {
      window.location.reload();
    },
    successMessage(value) {
      if (value !== false) {
        window.scrollTo(0, 0);
      }
    },
    isLoading(value) {
      this.componentKey++;
    },
    copyrightAndPrivacyDialog(value) {
      if (value !== true) {
        this.dataPrivacy = false;
        this.copyrightEN = false;
        this.copyrightDE = false;
        this.legalNotice = false;
      }
    },
    country(country: string) {
      if (!dachCountries.includes(country.toLowerCase())) {
        this.$i18n.locale = "en";
      }
    },
  },
  computed: {
    ...mapState({
      responseFromBackend: (state: any) =>
        state.customerManagement.responseFromBackend,
      valid: (state: any) => state.customerManagement.valid,
      notification: (state: any) => state.customerManagement.notification,
      expired: (state: any) => state.customerManagement.expired,
      manufacturerNumber: (state: any) =>
        state.customerManagement.customer.manufacturerNumber,
      customerName: (state: any) => state.customerManagement.customer.name,
      country: (state: any) =>
        state.customerManagement.customer.address.country,
      inMaintenance: (state: any) => state.customerManagement.inMaintenance,
    }),
    isMobile() {
      return this.$vuetify.breakpoint.width < 768;
    },
    smallerDesktop() {
      return (
        this.$vuetify.breakpoint.width < 1072 &&
        this.$vuetify.breakpoint.width >= 768
      );
    },
    reportType() {
      const hashLocation = window.location.hash as string;
      if (hashLocation.includes("yearly-forecast")) {
        return 1;
      } else if (hashLocation.includes("plan-correction")) {
        return 2;
      } else if (hashLocation.includes("yearly-report")) {
        return 3;
      }
      return 1;
    },
    submittedReport() {
      return this.notification.done || this.successMessage;
    },
    isWrongPage() {
      return (
        !this.isLoading &&
        !this.valid &&
        !this.expired &&
        !this.privacyPolicy &&
        !this.imprint &&
        !this.impressum &&
        !this.inMaintenance
      );
    },
  },
  methods: {
    openDataPrivacy() {
      this.dataPrivacy = true;
      this.copyrightAndPrivacyDialog = true;
    },
    openCopyrightEN() {
      this.copyrightEN = true;
      this.copyrightAndPrivacyDialog = true;
    },
    openCopyrightDE() {
      this.copyrightDE = true;
      this.copyrightAndPrivacyDialog = true;
    },
    openLegalNotice() {
      this.legalNotice = true;
      this.copyrightAndPrivacyDialog = true;
    },
  },
});
</script>
<style lang="sass">
.no-deco
  text-decoration: none !important
.color-primer
  color: #8fedc2
.main-card
  max-width: 1600px !important
  margin: auto
.colored-text
  white-space: pre
  color: #8fedc2
.hint-text
  color: #b8b8b8
  font-size: 0.875rem
.amount-table-header
  background-color: #8fedc2
  color: black
  font-size: 1rem !important
.amount-table
  border-bottom-left-radius: 10px !important
  border-bottom-right-radius: 10px !important
  border-top-left-radius: 10px !important
  border-top-right-radius: 10px !important
.amount-table .v-data-table__wrapper
  border-radius: 10px !important
.amount-table tr
  height: 65px !important
.amount-table th, .amount-table td
  max-width: 320px
.amount-table-border
  border-right: 3px dashed #dedede
.amount-table-border-green
  border-right: 3px dashed #81ECB5
.amount-table th.amount-table-border
  border-right: 3px dashed #20dc86
.amount-table th, .amount-table td
  color: #212529 !important
.difference-td, .total-td
  background-color: #e8fcf3
  border-bottom: thin solid #8fedc2 !important
tr:last-child .difference-td , tr:last-child .total-td
  border-bottom: none !important
.amount-table.elevation-4
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0), 0px 5px 8px 0px rgba(0, 0, 0, 0.11), 0px 1px 14px 0px rgba(0, 0, 0, 0.07) !important
.mobile-view
  overflow: hidden
.material-icon .v-image__image
  width: 4rem !important
  height: 4rem !important
</style>
<style lang="css">
@font-face {
  font-family: "Material Icons";
  src: url("fonts/material_icons/icon1.eot");
  src: url("fonts/material_icons/icon2.eot") format("embedded-opentype"),
    url("fonts/material_icons/materialDesign_font1.woff2") format("woff2"),
    url("fonts/material_icons/materialDesign_font2.woff") format("woff"),
    url("fonts/material_icons/materialDesign_icon.ttf") format("truetype");
}

.material-icons {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
}

/* latin-ext */
@font-face {
  font-family: "Gudea";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url("fonts/gudea/gudea-italic-400-one.woff2") format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Gudea";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url("fonts/gudea/gudea-italic-400-two.woff2") format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: "Gudea";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("fonts/gudea/gudea-normal-400-one.woff2") format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Gudea";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("fonts/gudea/gudea-normal-400-two.woff2") format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: "Gudea";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("fonts/gudea/gudea-normal-700-one.woff2") format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Gudea";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("fonts/gudea/gudea-normal-700-two.woff2") format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: "Magra";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("fonts/magra/magra-normal-400-one.woff2") format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Magra";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("fonts/magra/magra-normal-400-two.woff2") format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: "Magra";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("fonts/magra/magra-normal-700-one.woff2") format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Magra";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("fonts/magra/magra-normal-700-two.woff2") format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
</style>
