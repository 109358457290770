import { render, staticRenderFns } from "./Upload.vue?vue&type=template&id=ed1a1552&scoped=true"
var script = {}
import style0 from "./Upload.vue?vue&type=style&index=0&id=ed1a1552&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ed1a1552",
  null
  
)

export default component.exports