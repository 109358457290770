/* eslint-disable no-console */

import { register } from "register-service-worker";
import { EventBus } from "@/helpers";

if (process.env.NODE_ENV === "production") {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready() {
      console.log("App is being served from cache by a service worker.\n");
    },
    registered() {
      console.log("Service worker has been registered.");
    },
    cached() {
      console.log("Content has been cached for offline use.");
    },
    updatefound() {
      console.log("New content is downloading.");
      EventBus.$emit("dsz-sw", "Neuer Inhalt wird herunter geladen.");
    },
    updated(registration) {
      console.log("New update is available.");
      EventBus.$emit(
        "dsz-update",
        "Ein Update ist verfügbar! Die Seite wird in wenigen Sekunden automatisch aktualisiert.",
        {
          detail: registration,
        }
      );
    },
    offline() {
      console.log(
        "No internet connection found. App is running in offline mode."
      );
      EventBus.$emit(
        "dsz-sw",
        "Die Internetverbindung wurde unterbrochen. Die Anwendung ist nun im Offline-Modus."
      );
    },
    error(error) {
      console.error("Error during service worker registration:", error);
    },
  });
}
