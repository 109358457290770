<template>
  <v-container class="table-container ma-0 pa-0 pt-16">
    <v-card class="mx-0 px-0 pb-16" v-if="materials" flat>
      <v-card-title class="headline font-weight-bold px-0 mx-0 pb-6"
        ><v-icon color="black" class="pl-0 pr-4" size="60px"
          >$vuetify.icons.two</v-icon
        >
        {{ $t("DashboardSectionTwoHeading.jahresabschlussmenge") }}
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="materials"
        item-key="materialId"
        :items-per-page="-1"
        class="elevation-4 amount-table"
        :hide-default-footer="true"
      >
        <template v-slot:body="{ items }">
          <tbody>
            <tr v-for="item in items" :key="item.materialId">
              <td class="text-md-body-1 pl-10">
                <div
                  class="mx-0 px-0"
                  style="display: flex; align-items: center"
                >
                  <div
                    class="px-0 pt-4 mx-0"
                    style="
                      margin-bottom: -10px;
                      margin-top: -6px;
                      width: 5rem;
                      height: 6rem;
                    "
                  >
                    <v-img :src="item.image" class="material-icon"></v-img>
                  </div>
                  <div
                    class="mx-0"
                    style="align-items: left; min-width: max-content"
                  >
                    {{ $t(`materials.${item.materialNumber}`) }}
                  </div>
                </div>
              </td>
              <template
                v-for="currentMaterial in currentReport.materialVolumes"
              >
                <td
                  class="text-md-body-1 text-right amount-table-border"
                  style="padding-right: 3.5rem !important"
                  v-if="currentMaterial.materialId === item.materialId"
                  :key="currentMaterial.materialId"
                >
                  {{
                    currentMaterial.forecastAmount &&
                    currentMaterial.forecastAmount !== null
                      ? currentMaterial.forecastAmount.toLocaleString(locale, {
                          minimumFractionDigits: 3,
                          maximumFractionDigits: 3,
                        })
                      : Number(0).toLocaleString(locale, {
                          minimumFractionDigits: 3,
                          maximumFractionDigits: 3,
                        })
                  }}
                  {{ currentMaterial.unit }}
                </td>
              </template>
              <td class="text-md-body-1 text-center">
                <template v-for="materialAmount in materialAmounts">
                  <v-text-field
                    v-model="materialAmount.amount"
                    class="text-md-body-1 text-right text-field-td"
                    :rules="decimalRules"
                    dense
                    outlined
                    flat
                    solo
                    suffix="kg"
                    hide-details="auto"
                    v-if="materialAmount.materialId === item.materialId"
                    :key="materialAmount.materialId"
                    :placeholder="numberPlaceholder"
                    @keypress.native="checkUserInput"
                    @focus="handleFocus"
                    @input="calculateDifference(materialAmount)"
                  ></v-text-field>
                </template>
              </td>
              <td
                style="
                  background: #e4fcf1;
                  max-width: 2rem;
                  overflow: hidden !important;
                "
                class="amount-table-border-green px-0"
              >
                <template v-for="materialAmount in materialAmounts">
                  <v-col
                    cols="12"
                    class="pr-0 pl-2 py-0 d-flex"
                    v-if="materialAmount.materialId === item.materialId"
                    :key="materialAmount.materialId"
                  >
                    <v-col
                      v-if="materialAmount.difference !== undefined"
                      cols="10"
                      class="px-0 py-0 d-inline justify-center"
                      style="display: flex !important"
                    >
                      <div
                        class="px-0 pr-2 mx-0"
                        v-if="materialAmount.difference !== undefined"
                      >
                        <template v-if="materialAmount.difference > 0"
                          >+
                        </template>
                        <template v-else-if="materialAmount.difference < 0"
                          >-
                        </template>
                        <template v-else>= </template>
                      </div>
                      {{
                        Math.abs(materialAmount.difference).toLocaleString(
                          locale,
                          {
                            minimumFractionDigits: 3,
                          }
                        )
                      }}
                      {{ materialAmount.unit }}
                    </v-col>
                  </v-col>
                </template>
              </td>
              <td
                style="background: #e4fcf1; max-width: 2rem; overflow: hidden"
                class="px-0"
              >
                <template v-for="materialAmount in materialAmounts">
                  <v-col
                    cols="12"
                    class="pr-0 pl-2 py-0 d-flex"
                    v-if="materialAmount.materialId === item.materialId"
                    :key="materialAmount.materialId"
                  >
                    <v-col
                      cols="10"
                      class="px-0 py-0 d-inline justify-center"
                      style="display: flex !important"
                    >
                      <div class="px-0 pr-2 mx-0">
                        <template v-if="materialAmount.difference > 0"
                          >+
                        </template>
                        <template v-else-if="materialAmount.difference < 0"
                          >-
                        </template>
                        <template v-else>= </template>
                      </div>
                      {{
                        localizeSumWithNoDecimals(
                          Math.abs(materialAmount.differencePercentage)
                        )
                      }}
                      %
                    </v-col>
                  </v-col>
                </template>
              </td>
            </tr>
          </tbody>
        </template>
      </v-data-table>
      <span class="hint-text mx-0 mt-2 text-right pr-0 d-block">
        {{ $t("decimalHint") }}
      </span>
    </v-card>
    <signature-form @signData="updateSignature" :key="signatureKey" />
    <div class="button-width mx-auto my-12 px-0 py-16">
      <v-btn
        color="primary"
        class="send-button"
        rounded
        depressed
        x-large
        width="100%"
        height="65px"
        @click="checkForZero"
        :disabled="infoEditMode || !safeToSave"
        ><v-icon size="40px" class="mr-2"> $vuetify.icons.sendButton </v-icon>
        {{ $t("btnReportQuantities") }}</v-btn
      >
      <span class="hint-text hint-middle mx-auto mt-4 text-center d-block">{{
        $t("btnClickDescription")
      }}</span>
    </div>
    <v-dialog
      v-model="showThresholdOverlay"
      max-width="666"
      width="40%"
      overlay-color="white"
      overlay-opacity="0.8"
      class="rounded-lg"
      persistent
    >
      <v-card>
        <v-container class="pa-16">
          <h1 style="text-align: center" class="pb-6">
            {{ $t("DashboardTableHeader.securityOverlayHeading1") }} <br />{{
              $t("DashboardTableHeader.securityOverlayHeading2")
            }}
            <v-icon
              @click="closeMe"
              style="
                color: rgba(0, 0, 0, 0.87) !important;
                position: absolute;
                font-size: 3rem;
                top: 4% !important;
                right: 4% !important;
              "
              >mdi-close-circle-outline</v-icon
            >
          </h1>
          <div class="pb-8 pl-0 pr-0 pt-0">
            {{ $t("DashboardTableHeader.securityOverlayText") }}
            <a
              href="mailto:mengenmeldung@dualessystemzentek.de"
              style="text-decoration: none"
            >
              mengenmeldung@dualessystemzentek.de</a
            >
          </div>
          <v-btn
            color="primary"
            class="send-button"
            rounded
            depressed
            x-large
            width="100%"
            height="65px"
            @click="sendReport"
            :disabled="infoEditMode || !safeToSave"
          >
            {{ $t("DashboardTableHeader.btnOkReportQuantities") }}</v-btn
          >
        </v-container>
      </v-card>
    </v-dialog>
    <loading-component :isLoading="submitClicked" />
    <v-dialog v-model="isZero" max-width="600" overlay-color="white" persistent>
      <zero-warning @submit-zero="sendData" @close-me="isZero = false" />
    </v-dialog>
  </v-container>
</template>
<script lang="ts">
import LoadingComponent from "@/components/atoms/LoadingComponent.vue";
import { ApiUrl } from "@/config/ApiUrl";
import axios from "axios";
import Vue from "vue";
import { mapState } from "vuex";
import {
  convertLocalNumberStringToFloat,
  englishDecimalRules,
  germanDecimalRules,
  validateEnglishDecimals,
  validateGermanDecimals,
} from "../helpers";
import SignatureForm from "./atoms/SignatureForm.vue";
import ZeroWarning from "./atoms/ZeroWarning.vue";

interface YearlyReportRequest {
  manufacturerId: number;
  reportId: string;
  materialVolumes: [
    {
      materialId: number;
      unit: string;
      amount: number;
    }
  ];
  businessYear: number;
  submitDate: string;
  reporter: {
    firstName: string;
    lastName: string;
    signature: string;
  };
}
export default Vue.extend({
  name: "Yearly",
  props: {
    infoEditMode: Boolean,
  },
  components: {
    LoadingComponent,
    ZeroWarning,
    SignatureForm,
  },
  data: () => {
    return {
      inputMaterials: [] as any,
      headers: [
        /* header for TVP Tables*/
        {
          text: "Verkaufsverpackung",
          align: "start",
          class: "amount-table-header pl-10",
          sortable: false,
          width: "28%",
        },
        {
          text: "Planmenge",
          align: "center",
          class: "amount-table-header",
          sortable: false,
          width: "18%",
        },
        {
          text: "Jahresabschlussmenge",
          align: "center",
          class: "amount-table-header",
          sortable: false,
          width: "20%",
        },
        {
          text: "Differenz kg",
          align: "center",
          class: "amount-table-header",
          sortable: false,
          width: "16%",
        },
        {
          text: "Differenz %",
          align: "center",
          class: "amount-table-header",
          sortable: false,
          width: "16%",
        },
      ],
      materialAmounts: [] as any,
      submitClicked: false,
      isZero: false,
      declarationRequired: false,
      showThresholdOverlay: false,
      maxValuesChecked: false,
      signatureKey: 0,
      reporter: {
        firstName: "" as string,
        lastName: "" as string,
        signature: "" as string,
      },
    };
  },
  computed: {
    ...mapState({
      manufacturerId: (state: any) => state.customerManagement.customer.id,
      customerNumber: (state: any) =>
        state.customerManagement.customer.customerNumber,
      materials: (state: any) => state.customerManagement.customer.materials,
      businessYear: (state: any) =>
        state.customerManagement.notification.businessYear,
      reportId: (state: any) => state.customerManagement.notification.reportId,
      currentReport: (state: any) => state.customerManagement.report,
    }),
    dataComplete() {
      if (
        !this.reporter.signature ||
        this.reporter.signature === null ||
        this.reporter.signature === "" ||
        this.reporter.firstName === null ||
        this.reporter.firstName.trim() === "" ||
        this.reporter.lastName === null ||
        this.reporter.lastName.trim() === ""
      )
        return false;
      return true;
    },
    locale() {
      if (this.$i18n.locale === "en") {
        return "en-GB";
      }
      return "de-DE";
    },
    numberPlaceholder() {
      if (this.$i18n.locale === "en") {
        return "0.000";
      }
      return "0,000";
    },
    decimalRules() {
      if (this.$i18n.locale === "en") {
        return englishDecimalRules;
      }
      return germanDecimalRules;
    },
    safeToSave() {
      let isValid = true;
      for (const materialAmount of this.materialAmounts) {
        const singleValid =
          this.$i18n.locale === "de"
            ? validateGermanDecimals(materialAmount.amount)
            : validateEnglishDecimals(materialAmount.amount);
        if (!singleValid || !this.dataComplete) {
          isValid = false;
        }
      }
      return isValid;
    },
  },
  mounted() {
    const materialAmounts = [] as any;
    for (const material of this.materials) {
      const materialAmount = {
        materialId: material.materialId,
        amount: "",
        forecastAmount: "" as any,
        difference: 0 as number,
        differencePercentage: 0 as number,
        unit: "kg",
      };
      materialAmounts.push(materialAmount);
    }

    this.materialAmounts = materialAmounts;
    this.headers[0].text = this.$i18n.t(
      "DashboardTableHeader.salesPackaging"
    ) as string;
    this.headers[1].text = `${
      this.$i18n.t("DashboardTableHeader.planmenge") as string
    } ${this.businessYear}`;
    this.headers[2].text = `${
      this.$i18n.t("DashboardTableHeader.yearEndQuantity") as string
    } ${this.businessYear}`;
    this.headers[3].text = `${
      this.$i18n.t("DashboardTableHeader.diffKg") as string
    }`;
    this.headers[4].text = `${
      this.$i18n.t("DashboardTableHeader.diffPer") as string
    }`;
  },
  watch: {
    "$i18n.locale": function (newVal, oldVal) {
      this.headers[0].text = this.$i18n.t(
        "DashboardTableHeader.salesPackaging"
      ) as string;
      this.headers[1].text = `${
        this.$i18n.t("DashboardTableHeader.planmenge") as string
      } ${this.businessYear}`;
      this.headers[2].text = `${
        this.$i18n.t("DashboardTableHeader.yearEndQuantity") as string
      } ${this.businessYear}`;
      this.headers[3].text = `${
        this.$i18n.t("DashboardTableHeader.diffKg") as string
      }`;
      this.headers[4].text = this.$i18n.t(
        "DashboardTableHeader.diffPer"
      ) as string;
      this.signatureKey++;
    },
    materialAmounts: function (newVal, oldVal) {
      for (const materialAmount of newVal) {
        for (const currentMaterial of this.currentReport.materialVolumes) {
          if (
            currentMaterial.materialId === materialAmount.materialId &&
            materialAmount.amount === ""
          ) {
            materialAmount.difference = 0 - currentMaterial.forecastAmount;
            materialAmount.differencePercentage =
              currentMaterial.forecastAmount > 0 &&
              currentMaterial.forecastAmount !== null
                ? ((0 - currentMaterial.forecastAmount) /
                    currentMaterial.forecastAmount) *
                  100
                : 0;
          }
        }
      }
    },
  },
  methods: {
    calculateDifference(materialAmount: any) {
      if (this.$i18n.locale === "en") {
        const valid = !materialAmount.amount.includes(",,");
        if (valid) {
          const firstPart = materialAmount.amount.split(".")[0];
          let secondPart = "";
          if (materialAmount.amount.split(".").length > 1) {
            for (let x = 1; x < materialAmount.amount.split(".").length; x++) {
              secondPart += `.${materialAmount.amount.split(".")[x]}`;
            }
          }
          materialAmount.amount = `${firstPart
            .replace(/,/g, "")
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}${secondPart}`;
        }
      } else {
        const valid = !materialAmount.amount.includes("..");
        if (valid) {
          const firstPart = materialAmount.amount.split(",")[0];
          let secondPart = "";
          if (materialAmount.amount.split(",").length > 1) {
            for (let x = 1; x < materialAmount.amount.split(",").length; x++) {
              secondPart += `,${materialAmount.amount.split(",")[x]}`;
            }
          }
          materialAmount.amount = `${firstPart
            .replace(/\./g, "")
            .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}${secondPart}`;
        }
      }
      let amountString =
        materialAmount.amount === "" ? "0" : materialAmount.amount;
      for (let currentMaterial of this.currentReport.materialVolumes) {
        if (currentMaterial.materialId === materialAmount.materialId) {
          const difference =
            convertLocalNumberStringToFloat(
              this.locale,
              materialAmount.amount === "" ? "0" : materialAmount.amount
            ) -
            (currentMaterial.forecastAmount &&
            currentMaterial.forecastAmount !== null
              ? currentMaterial.forecastAmount
              : 0);
          materialAmount.difference = difference;
          if (difference === 0) {
            materialAmount.differencePercentage = 0;
            break;
          }
          materialAmount.differencePercentage =
            currentMaterial.forecastAmount > 0 &&
            currentMaterial.forecastAmount !== null
              ? (difference / currentMaterial.forecastAmount) * 100
              : 100;
          break;
        }
      }
    },
    localize(value: any) {
      if (this.$i18n.locale === "en") {
        return value.replace(/,/g, "");
      } else {
        return value.replace(/\./g, "");
      }
    },
    localizeSum(value: number) {
      let stringValue = value.toLocaleString(this.locale, {
        minimumFractionDigits: 3,
        maximumFractionDigits: 3,
      });
      if (this.$i18n.locale === "en") {
        stringValue = stringValue.replace(/,/g, "");
      } else {
        stringValue = stringValue.replace(/\./g, "");
      }
      return stringValue;
    },
    localizeSumWithNoDecimals(value: number) {
      let stringValue = value.toLocaleString(this.locale, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      });
      return stringValue;
    },
    checkUserInput($userInput: any) {
      const keyCode = $userInput.keyCode ? $userInput.keyCode : $userInput.key;

      // allow comma: 44 and dot: 46
      if (keyCode !== 44 && keyCode !== 46) {
        // allow numbers
        if (keyCode < 48 || keyCode > 57) {
          $userInput.preventDefault();
        }
      }
    },
    handleFocus() {
      if (this.infoEditMode !== false) {
        this.$vuetify.goTo("#customerInfo");
        this.$toast.error(
          this.$t("toastTexts.saveOrDiscardChangesBeforeContinuing") as string
        );
      }
    },
    closeMe() {
      this.showThresholdOverlay = false;
    },
    sendReport() {
      this.closeMe();
      this.sendData();
    },
    checkForMaxValues() {
      let totalAmount = 0;
      for (let i = 0; i < this.materialAmounts.length; i++) {
        const amount = convertLocalNumberStringToFloat(
          this.locale,
          this.materialAmounts[i] && this.materialAmounts[i].amount
            ? this.materialAmounts[i].amount
            : "0"
        );
        if (
          (this.materialAmounts[i].materialId === 191 && amount >= 80000) ||
          (this.materialAmounts[i].materialId === 192 && amount >= 50000) ||
          (this.materialAmounts[i].materialId !== 191 &&
            this.materialAmounts[i].materialId !== 192 &&
            this.materialAmounts[i].materialId !== 198 &&
            amount >= 30000)
        ) {
          this.showThresholdOverlay = true;
          this.declarationRequired = true;
          break;
        } else if (
          this.materialAmounts[i].materialId !== 198 &&
          this.materialAmounts[i].materialId !== 191 &&
          this.materialAmounts[i].materialId !== 192 &&
          amount < 30000
        ) {
          totalAmount += amount;
        }
      }
      if (totalAmount >= 30000) {
        this.showThresholdOverlay = true;
        this.declarationRequired = true;
      }
      if (this.showThresholdOverlay === false) {
        this.sendData();
      }
    },
    checkForZero() {
      let zeroAmount = true;
      for (const materialAmount of this.materialAmounts) {
        if (
          materialAmount.amount !== "0" &&
          materialAmount.amount !== "" &&
          materialAmount.amount !== "0.000" &&
          materialAmount.amount !== "0,000"
        ) {
          zeroAmount = false;
          break;
        }
      }
      if (zeroAmount !== false) {
        this.isZero = true;
      } else {
        this.checkForMaxValues();
      }
    },
    updateSignature(signData: any) {
      this.reporter.signature =
        signData.signature && signData.signature !== null
          ? signData.signature
          : "";
      this.reporter.firstName =
        signData.firstName && signData.firstName !== null
          ? signData.firstName
          : "";
      this.reporter.lastName =
        signData.lastName && signData.lastName !== null
          ? signData.lastName
          : "";
    },
    sendData() {
      this.isZero = false;
      this.submitClicked = true;
      const finalMaterialAmounts = JSON.parse(
        JSON.stringify(this.materialAmounts)
      );
      for (const materialAmount of finalMaterialAmounts) {
        materialAmount.amount = convertLocalNumberStringToFloat(
          this.locale,
          materialAmount.amount === "" ? "0" : materialAmount.amount
        );
        delete materialAmount.difference;
        delete materialAmount.differencePercentage;
      }
      const payload: YearlyReportRequest = {
        manufacturerId: this.manufacturerId,
        reportId: this.reportId,
        materialVolumes: finalMaterialAmounts,
        businessYear: this.businessYear,
        submitDate: new Date().toLocaleDateString("de-DE", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
        }),
        reporter: this.reporter,
      };
      /* AXIOS POST REQUEST HERE  */
      this.$store.dispatch("checkMaintenanceStatus").then((status) => {
        if (status !== true) {
          axios({
            url: ApiUrl.REPORT_SUBMIT,
            method: "POST",
            data: payload,
          })
            .then((apiResponse: any) => {
              if (apiResponse.response && apiResponse.response.data.error) {
                this.submitClicked = false;
                this.$toast.error(
                  this.$t("toastTexts.internalError") as string
                );
                return false;
              } else {
                this.submitClicked = false;
                this.$emit("successfully-submitted");
              }
            })
            .catch((error) => {
              this.submitClicked = false;
              this.$toast.error(this.$t("toastTexts.internalError") as string);
            });
        } else {
          window.location.reload();
        }
      });
    },
    convertMe(numberString: string) {
      return convertLocalNumberStringToFloat(this.locale, numberString);
    },
  },
});
</script>
<style scoped>
.table-container {
  width: 100%;
}
.text-field-td {
  width: 75%;
  margin-left: auto;
  margin-right: auto;
}
.text-field-td >>> input {
  text-align: right;
}
.text-field-td >>> .v-text-field__suffix {
  color: grey;
}
.text-field-td.v-text-field--outlined:not(.v-input--is-focused) >>> fieldset {
  border-color: rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.1);
}
.text-field-td >>> .v-text-field__details {
  margin-bottom: 0px;
  max-width: 100%;
  width: 100%;
  font-size: 0.3rem !important;
}
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  font-size: 1rem;
}
.text-field-td >>> .v-messages {
  font-size: 11px;
  height: 11px;
}
.button-width {
  width: 40%;
}
.send-button >>> span.v-btn__content {
  color: black;
  text-transform: none;
  font-weight: bold;
}
.send-button {
  border-radius: 42px;
}
.button-width .hint-middle {
  width: 80%;
}
</style>
