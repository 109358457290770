const url = process.env.VUE_APP_API_URL;

export abstract class ApiUrl {
  public static readonly CUSTOMER_INFO = `${url}/customer`;
  public static readonly MAINTENANCE_CHECK = `${url}/maintenance-check`;
  public static readonly FORECAST_REPORT_SUBMIT = `${url}/report-forecast-amount`;
  public static readonly PLAN_CORRECTION_SUBMIT = `${url}/report-correction-amount`;
  public static readonly REPORT_SUBMIT = `${url}/report-actual-amount`;
  public static readonly SEND_MESSAGE = `${url}/sendInquiryMessage`;
}
