var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-app',{class:{ 'mobile-view': _vm.isMobile }},[(!_vm.isMobile)?_c('app-drawer',{key:_vm.componentKey,attrs:{"successMessage":_vm.successMessage,"submittedReport":_vm.submittedReport,"separateImprint":_vm.imprint || _vm.impressum,"isLoading":_vm.isLoading}}):_vm._e(),(
      !_vm.isMobile && !_vm.successMessage && !_vm.submittedReport && _vm.valid && !_vm.isLoading
    )?_c('sub-drawer',{attrs:{"reportType":_vm.reportType}}):_vm._e(),(!_vm.isMobile && _vm.valid && _vm.submittedReport)?_c('sub-drawer-done',{attrs:{"successMessage":_vm.successMessage,"reportType":_vm.reportType}}):_vm._e(),_c('v-main',[(
        !_vm.isMobile &&
        !_vm.successMessage &&
        !_vm.submittedReport &&
        _vm.valid &&
        !_vm.isLoading
      )?_c('router-view',{class:{ mobile: _vm.isMobile, desktop: !_vm.isMobile },attrs:{"reportType":_vm.reportType,"customerLinkData":_vm.customerLinkData},on:{"submission-done":function($event){_vm.successMessage = true}}}):_vm._e(),(_vm.isMobile)?_c('mobile-view'):_vm._e(),(
        !_vm.isMobile &&
        !_vm.successMessage &&
        !_vm.submittedReport &&
        _vm.valid &&
        !_vm.isLoading
      )?_c('contact-form',{key:_vm.componentKey,attrs:{"manufacturerNumber":_vm.manufacturerNumber,"customerName":_vm.customerName},on:{"open-data-privacy":_vm.openDataPrivacy,"successfully-send-email":function($event){_vm.componentKey += 1}}}):_vm._e()],1),(_vm.isLoading)?_c('skeleton-loader'):_vm._e(),(_vm.isWrongPage)?_c('wrong-page'):_vm._e(),(_vm.inMaintenance === true)?_c('maintenance-page'):_vm._e(),(_vm.privacyPolicy)?_c('privacy-policy-page'):_vm._e(),(_vm.imprint)?_c('copyright-english-page'):_vm._e(),(_vm.impressum)?_c('copyright-german-page'):_vm._e(),(!_vm.valid && _vm.expired && !_vm.privacyPolicy && !_vm.imprint && !_vm.impressum)?_c('expired-link'):_vm._e(),(!_vm.isMobile)?_c('footer-drawer',{on:{"open-data-privacy":_vm.openDataPrivacy,"open-copyright-en":_vm.openCopyrightEN,"open-copyright-de":_vm.openCopyrightDE,"open-legal-notice":_vm.openLegalNotice}}):_vm._e(),_c('v-dialog',{staticClass:"rounded-lg",attrs:{"scrollable":"","max-width":"1600","width":"75%","overlay-color":"white","overlay-opacity":"0.4"},model:{value:(_vm.copyrightAndPrivacyDialog),callback:function ($$v) {_vm.copyrightAndPrivacyDialog=$$v},expression:"copyrightAndPrivacyDialog"}},[(_vm.dataPrivacy)?_c('data-privacy',{on:{"close-this":function($event){_vm.copyrightAndPrivacyDialog = false}}}):_vm._e(),(_vm.copyrightEN)?_c('copy-right-english',{on:{"close-this":function($event){_vm.copyrightAndPrivacyDialog = false}}}):_vm._e(),(_vm.copyrightDE)?_c('copy-right-german',{on:{"close-this":function($event){_vm.copyrightAndPrivacyDialog = false}}}):_vm._e(),(_vm.legalNotice)?_c('legal-notice',{on:{"close-this":function($event){_vm.copyrightAndPrivacyDialog = false}}}):_vm._e()],1),_c('app-update',{class:{ mobile: _vm.isMobile, desktop: !_vm.isMobile }})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }