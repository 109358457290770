<template>
  <v-sheet class="px-12 rounded-lg imprint">
    <v-card-title
      class="display-1 font-weight-bold mt-8 pb-8 text-left text-uppercase"
    >
      Impressum
    </v-card-title>
    <v-card-subtitle class="font-weight-bold black--text pb-0">
      <p>Zentek GmbH & Co. KG</p>
    </v-card-subtitle>
    <v-card-text class="black--text">
      <p>
        Zentek Verwaltungs GmbH als persönlich haftende Gesellschafterin <br />
        vertreten durch den Geschäftsführer <br />
        Dipl.-Kaufmann Christoph Ley <br />
        Ettore-Bugatti-Str. 6-14 <br />
        51149 Köln <br /><br />
        Telefon: 02203 8987-500 <br />
        Fax: 02203 8987-777 <br />
        E-Mail:
        <a href="mengenmeldung@dualessystemzentek.de"
          >mengenmeldung@dualessystemzentek.de</a
        >
        <br /><br />
        Handelsregister: Amtsgericht Köln <br />
        Registernummer: HRA Köln 13559 <br />
        UstID: DE 173 788 828 <br /><br />
        <span class="font-weight-bold"
          >Pflichtangaben gem. §§ 14 Abs. 4, 26 Abs. 1 Satz 2 Nr. 6a
          VerpackG:</span
        ><br /><br />
        Gesellschafter der Zentek GmbH & Co KG sind: <br /><br />
        Brockmann Recycling GmbH <br />
        Böhme GmbH<br />HEINZ Entsorgung GmbH & Co. KG<br />Jakob Becker GmbH &
        Co. KG<br />Lobbe Entsorgung West GmbH & Co. KG<br />Lobbe Entsorgung
        GmbH<br />Meinhardt Entsorgung Beteiligung GmbH<br />Nehlsen AGReiling
        GmbH & Co. KG<br />WRZ Wertstoffrückgewinnungszentrum Hörger GmbH & Co.
        KG<br /><br />
        Die Entgelte für die Beteiligung an unserem System können unserem
        Angebotskalkulator entnommen werden. Weitergehende Preisinformationen
        über geleistete Entgelte sind Geschäftsgeheimnisse unseres Unternehmens
        <br /><br />
        Die Auswahl der Abfallbewirtschaftungseinrichtungen, die nicht nach § 23
        ausgewählt werden, erfolgt unter Berücksichtigung von Preis- und
        Leistungskriterien aus dem Kreis der Unternehmen, die die gesetzlichen
        Vorgaben und von uns geforderten Qualifikationen erfüllen. <br /><br />
        <span class="font-weight-bold"
          >Aufsichtsbehörden für das Duale System Zentek sind:</span
        ><br /><br />

        <span class="font-weight-bold">Schleswig-Holstein</span><br />
        Ministerium für Landwirtschaft, Umwelt und ländliche Räume des Landes
        Schleswig-Holstein, Mercatorstraße 3, 5, 7, 24106 Kiel<br /><br />
        <span class="font-weight-bold">Freie und Hansestadt Hamburg</span><br />
        Behörde für Stadtentwicklung und Umwelt, Billstraße 84, 20539 Hamburg<br /><br />
        <span class="font-weight-bold">Niedersachsen</span><br />
        Niedersächsisches Umweltministerium, Archivstraße 2, 30169 Hannover<br /><br />
        <span class="font-weight-bold">Freie Hansestadt Bremen</span><br />
        Der Senator für Bau, Umwelt und Verkehr, Ansgaritorstraße 2, 28195
        Bremen<br /><br />
        <span class="font-weight-bold">Nordrhein-Westfalen</span><br />
        Ministerium für Umwelt und Naturschutz, Landwirtschaft und
        Verbraucherschutz das Landes Nordrhein-Westfalen, Schwannstraße 3, 40476
        Düsseldorf<br /><br />
        <span class="font-weight-bold">Hessen</span><br />
        Hessisches Ministerium für Umwelt, ländlichen Raum und
        Verbraucherschutz, Mainzer Straße 80, 65189 Wiesbaden<br /><br />
        <span class="font-weight-bold">Rheinland-Pfalz</span><br />
        Ministerium für Umwelt, Landwirtschaft, Ernährung, Weinbau und Forsten,
        Kaiser-Friedrich-Straße 1, 55116 Mainz<br /><br />
        <span class="font-weight-bold">Baden-Württemberg</span><br />
        Umweltministerium Baden-Württemberg, Kernerplatz 9, 70182 Stuttgart<br /><br />
        <span class="font-weight-bold">Freistaat Bayern</span><br />
        Bayerisches Staatsministerium für Umwelt, Gesundheit und
        Verbraucherschutz, Rosenkavalierplatz 2, 81925 München<br /><br />
        <span class="font-weight-bold">Saarland</span><br />
        Landesamt für Umwelt- und Arbeitsschutz, Don-Bosco-Straße 1, 66119
        Saarbrücken<br /><br />
        <span class="font-weight-bold">Berlin</span><br />
        Senatsverwaltung für Gesundheit, Umwelt und Verbraucherschutz,
        Brückenstraße 6, 10179 Berlin<br /><br />
        <span class="font-weight-bold">Brandenburg</span><br />
        Ministerium für Ländliche Entwicklung, Umwelt und Verbraucherschutz des
        Landes Brandenburg, Heinrich-Mann-Allee 103, 14473 Potsdam<br /><br />
        <span class="font-weight-bold">Mecklenburg-Vorpommern</span><br />
        Landesamt für Umwelt, Naturschutz und Geologie Mecklenburg-Vorpommern,
        Goldberger Straße 12, 18273 Güstrow<br /><br />
        <span class="font-weight-bold">Freistaat Sachsen</span><br />
        Sächsisches Staatsministerium für Umwelt und Landwirtschaft,
        Archivstraße 1, 01097 Dresden<br /><br />
        <span class="font-weight-bold">Sachsen-Anhalt</span><br />
        Ministerium für Landwirtschaft und Umwelt das Landes Sachsen-Anhalt,
        Olvenstedter Straße 4, 39012 Magdeburg<br /><br />
        <span class="font-weight-bold">Thüringen</span><br />
        Ministerium für Landwirtschaft, Naturschutz und Umwelt, Beethovenstraße
        3, 99096 Erfurt<br /><br />
        Jede Verwertung innerhalb eines gewerblichen Unternehmens, wie
        Vervielfältigung jeder Art, Übersetzungen, Mikroverfilmungen und die
        Einspeicherung und Verarbeitung in elektronischen Systemen bedürfen der
        schriftlichen Einwilligung der Zentek GmbH & Co. KG. <br />
        <br />
        Auch wenn wir große Sorgfalt darauf verwenden, Ihnen qualitativ gute
        Links anzubieten, erklären wir hiermit, dass wir für die Inhalte der
        verlinkten Seiten keine Verantwortung übernehmen und uns diese nicht zu
        eigen machen. Diese Erklärung gilt für alle auf dieser Webseite
        angebrachten Links. In keinem Fall sind wir verantwortlich für die
        Inhalte von Internetseiten, die auf uns verweisen. Wir sind auch nicht
        in der Lage, Verweise auf uns festzustellen oder zu prüfen. Zentek GmbH
        & Co. KG hat ferner keinen Einfluss darauf, wie die hier angebotenen
        Informationen vom Nutzer verwendet, weitergegeben oder verändert
        weitergegeben werden. Zentek GmbH & Co. KG kann daher für entstandene
        Schäden, entgangene Vorteile oder sonstige mittelbare oder unmittelbare
        Folgen, welche aus der Nutzung der hier angebotenen Informationen
        entstehen, nicht haftbar gemacht werden. <br />
        <br />
      </p>
    </v-card-text>
  </v-sheet>
</template>
<script lang="ts">
import Vue from "vue";
export default Vue.extend({
  data: () => {
    return {};
  },
});
</script>
<style lang="sass" scoped>
.ln-1
  line-height: 1.5rem
.label-left
  display: inline-block
  width: 150px
.imprint
  width: 70%
  max-width: 1600px !important
  margin: auto
</style>
