<template>
  <v-footer dark padless>
    <div class="footer-group">
      <v-card flat class="lighten-1 white--text text-center footer-card">
        <v-col cols="12" class="px-0 pb-8 mx-0 mt-5 d-inline-flex">
          <v-col cols="3" class="pl-0">
            <h5 class="white--text text-left title-footer">
              {{ $t("Footer.Contact.heading") }}
            </h5>
            <span class="d-block footer-paragraph pt-4"
              >{{ "+492203 89 87 500" }}<br />
              {{ $t("Footer.Contact.openingTime") }}
              <br /><br />
              <a
                :href="'mailto:mengenmeldung@dualessystemzentek.de'"
                class="footer-paragraph"
              >
                {{ "mengenmeldung@dualessystemzentek.de" }} </a
              ><br /><br /><a
                href="https://www.dualessystemzentek.de"
                class="footer-paragraph"
                target="_blank"
              >
                www.dualessystemzentek.de
              </a>
            </span>
          </v-col>
          <v-col cols="3">
            <h5 class="white--text text-left title-footer">SERVICE</h5>
            <span class="d-block footer-paragraph pt-4">
              <a
                href="https://www.zentek.de/verpackungen/transportverpackungen/"
                target="_blank"
                class="footer-paragraph"
                >{{ $t("Footer.Service.licenseTransportPackaging") }} </a
              ><br /><br />
              <a
                href="https://www.zentek.de/elektrogeraete/"
                class="footer-paragraph"
                target="_blank"
                >{{ $t("Footer.Service.disposeOldElectricalEquipment") }} </a
              ><br /><br />
              <a
                href="https://www.zentek.de/internationale-services/"
                class="footer-paragraph"
                target="_blank"
                >{{ $t("Footer.Service.internationalService") }} </a
              ><br />
            </span>
          </v-col>
          <v-col cols="3">
            <h5 class="white--text text-left title-footer">
              {{ $t("Footer.Info.heading") }}
            </h5>
            <span class="d-block footer-paragraph pt-4">
              <a
                v-if="this.$i18n.locale === 'en'"
                @click="$emit('open-copyright-en')"
                class="footer-paragraph"
              >
                {{ $t("Footer.Info.imprint") }}</a
              >
              <a
                v-else
                @click="$emit('open-copyright-de')"
                class="footer-paragraph"
              >
                {{ $t("Footer.Info.imprint") }}</a
              >
              <br /><br />
              <a @click="$emit('open-data-privacy')" class="footer-paragraph">
                {{ $t("Footer.Info.privacyPolicy") }} </a
              ><br /><br />
              <a @click="$emit('open-legal-notice')" class="footer-paragraph">
                {{ $t("Footer.Info.legalNotice") }}
              </a>
            </span></v-col
          >
          <v-col cols="3">
            <h5 class="white--text text-left title-footer">
              {{ $t("Footer.socialHeading") }}
            </h5>
            <span class="d-block footer-paragraph pt-4">
              {{ $t("Footer.socialParagraph1") }}<br />
              {{ $t("Footer.socialParagraph2") }}<br />
              {{ $t("Footer.socialParagraph3") }}
            </span>
            <v-card-text class="text-left px-0">
              <v-btn
                class="text-left"
                href="https://www.facebook.com/zentekgruppe"
                target="_blank"
                icon
              >
                <v-icon size="36px"> $vuetify.icons.facebook </v-icon>
              </v-btn>
              <v-btn
                class="text-left"
                href="https://www.instagram.com/zentekgruppe/"
                target="_blank"
                icon
              >
                <v-icon size="36px"> $vuetify.icons.instagram </v-icon>
              </v-btn>
              <v-btn
                class="text-left"
                href="https://www.linkedin.com/company/zentek-gmbh-&-co.-kg/?challengeId=AQG5KC_S53qF0wAAAX9FeXCV26DTcUznOkG21OUl3X-zdiyaKwwT6OS2BSvMIv5jQAMrV8KBLmijSSGp4Vt5UNblfSrpnypvuw&submissionId=dbe18233-d841-d816-6698-9549518b494f"
                target="_blank"
                icon
              >
                <v-icon size="36px"> $vuetify.icons.linkedIn </v-icon>
              </v-btn>
            </v-card-text>
          </v-col>
        </v-col>

        <v-divider class="footer-divider"></v-divider>
        <v-col cols="12" class="px-0 py-8 ma-0 d-inline-flex">
          <v-col cols="4" class="pl-0">
            <div class="zentek-white"></div>
            <span class="d-block footer-paragraph pt-4">
              {{ $t("Footer.zentekParagraph1") }} <br />
              {{ $t("Footer.zentekParagraph2") }} <br />
              {{ $t("Footer.zentekParagraph3") }}
            </span>
            <span class="d-block pt-4" style="text-align: left">
              <a
                href="http://www.dualessystemzentek.de/"
                class="white--text footer-link"
                target="_blank"
              >
                www.dualessystemzentek.de
              </a>
            </span>
          </v-col>
          <v-col cols="4" style="visibility: hidden !important"
            ><div class="epd-white"></div>
            <span class="d-block footer-paragraph pt-4">
              {{ $t("Footer.epdParagraph1") }} <br />
              {{ $t("Footer.epdParagraph2") }} <br />
              {{ $t("Footer.epdParagraph3") }}
            </span>
            <span class="d-block pt-4" style="text-align: left">
              <a
                href="https://www.entsorgung.de"
                class="white--text footer-link"
                target="_blank"
              >
                www.entsorgung.de
              </a>
            </span></v-col
          >
          <v-col cols="4" class="d-inline-flex pl-15">
            <div class="round-logo"></div>
            <div class="round-logo ssl"></div>
          </v-col>
        </v-col>
      </v-card>
    </div>
    <v-card
      flat
      class="lighten-1 white--text text-center black"
      style="width: 100vw"
    >
      <div class="footer-group">
        <v-card-text class="text-left px-0" style="width: 75%; margin: auto">
          Zentek
          <sup
            ><v-icon style="font-size: 0.5rem; margin-right: 5px"
              >mdi-copyright</v-icon
            ></sup
          >-
          {{ new Date().getFullYear() }}
        </v-card-text>
      </div>
    </v-card>
  </v-footer>
</template>
<script lang="ts">
import Vue from "vue";

export default Vue.extend({
  props: {
    isTvp: Boolean,
  },
});
</script>
<style lang="sass">
.footer-group
  width: 100%
  max-width: 1600px !important
  margin: auto

.title-footer
  font-size: 0.85rem !important
  font-weight: bold
  line-height: 2rem
  letter-spacing: 0.0125em !important
  font-family: "Magra", sans-serif !important

.theme--dark.v-footer
  background-color: #212529 !important

.footer-card
  background-color: #212529 !important
  width: 75%
  margin: auto

.footer-divider
  border-color: rgba(255, 255, 255, 0.5) !important
  width: 100%
  margin: auto
  border-width: 2px 1px 1px 1px !important

.zentek-white
  width: 250px
  height: 40px
  background-image: url('../assets/Zentek_Logo_white_RGB.svg')
  background-repeat: no-repeat
  background-position: left center
  background-size: contain

.epd-white
  width: 171px
  height: 35px
  background-image: url('../assets/Epd.svg')
  background-repeat: no-repeat
  background-position: left center
  background-size: contain

.footer-paragraph
  color: #b8b8b8 !important
  font-size: 0.7rem
  letter-spacing: 0.0125em !important
  text-align: left
  text-decoration: none

.footer-link
  text-decoration: none
  font-weight: bold
  text-align: left !important
  cursor: pointer
  font-size: 0.9rem

.round-logo
  width: 105px
  height: 105px
  background-image: url('../assets/qms.png')
  background-repeat: no-repeat
  background-position: left
  background-size: contain

.ssl
  width: 112px
  height: 112px
  background-image: url('../assets/ssl_siegel.png')
  background-position: right !important
  margin-right: 10%
  margin-left: auto

.col-3
  margin-right: 28px !important

.col-4
  margin-right: 12px !important

@media only screen and (min-width: 1601px)
  .title-footer
    font-size: 1rem !important
  .zentek-white
    width: 190px
    height: 45px
  .epd-white
    width: 181px
    height: 45px
  .round-logo
    width: 128px
    height: 128px
  .ssl
    width: 135px
    height: 135px
    margin-right: 10%
  .footer-paragraph
    font-size: 0.85rem
  .footer-link
    font-size: 1rem
  .col-3
    margin-right: 40px !important
  .pl-15
    padding-left: 48px !important
</style>
