<template>
  <v-sheet class="px-12 rounded-lg imprint">
    <v-card-title
      class="display-1 font-weight-bold mt-8 pb-8 text-left text-uppercase"
    >
      IMPRINT
    </v-card-title>
    <v-card-subtitle class="font-weight-bold black--text pb-0">
      <p>Zentek GmbH & Co. KG</p>
    </v-card-subtitle>
    <v-card-text class="black--text">
      <p>
        Zentek Verwaltungs GmbH as responsible partner <br />represented by the
        CEO <br />
        Dipl.-Kaufmann Christoph Ley <br />
        Ettore-Bugatti-Str. 6-14 <br />
        51149 Cologne <br /><br />
        Phone: 02203 8987-500 <br />
        Fax: 02203 8987-777 <br />
        E-Mail:
        <a href="info@dualessystemzentek.de"
          >mengenmeldung@dualessystemzentek.de</a
        >
        <br />
        Commercial register: Cologne District Court <br />
        Registration number: HRA Köln 13559 <br />
        VAT ID: DE 173 788 828 <br /><br />
        <span class="font-weight-bold"
          >The regulatory authorities are the government agencies of the German
          federal states responsible for waste management. <br />
          The regulatory authorities for the Dual System Zentek are:</span
        ><br /><br />
        <span class="font-weight-bold">Schleswig-Holstein</span> <br />
        Ministry of Agriculture, Environment and Rural Areas of the State of
        Schleswig-Holstein, Mercatorstraße 3, 5, 7, 24106 Kiel, Germany
        <br /><br />
        <span class="font-weight-bold">Free and Hanseatic City of Hamburg</span
        ><br />
        Ministry for Urban Development and the Environment, Billstrasse 84,
        20539 Hamburg<br /><br />
        <span class="font-weight-bold">Lower Saxony</span> <br />
        Lower Saxony Ministry of the Environment, Archivstraße 2, 30169
        Hanover<br /><br />
        <span class="font-weight-bold">Free Hanseatic City of Bremen</span
        ><br />
        The Senator for Building, Environment and Transport, Ansgaritorstraße 2,
        28195 Bremen<br /><br />
        <span class="font-weight-bold">North Rhine-Westphalia</span><br />
        Ministry for the Environment and Conservation, Agriculture and Consumer
        Protection of the State of North Rhine-Westphalia, Schwannstraße 3,
        40476 Düsseldorf<br /><br />
        <span class="font-weight-bold">Hesse</span><br />
        Hessian Ministry for the Environment, Rural Areas and Consumer
        Protection, Mainzer Strasse 80, 65189 Wiesbaden<br /><br />
        <span class="font-weight-bold">Rhineland-Palatinate</span><br />
        Ministry for the Environment, Agriculture, Food, Viticulture and
        Forestry, Kaiser-Friedrich-Straße 1, 55116 Mainz<br /><br />
        <span class="font-weight-bold">Baden-Württemberg</span><br />
        Ministry of the Environment of Baden-Württemberg, Kernerplatz 9, 70182
        Stuttgart<br /><br />
        <span class="font-weight-bold">Free State of Bavaria</span><br />
        Bavarian State Ministry for the Environment, Public Health and Consumer
        Protection, Rosenkavalierplatz 2, 81925 Munich, Germany<br /><br />
        <span class="font-weight-bold">Saarland</span><br />
        State Office for Environmental Protection and Occupational Safety,
        Don-Bosco-Strasse 1, 66119 Saarbrücken<br /><br />
        <span class="font-weight-bold">Berlin</span><br />
        Senate Department for Health, Environment and Consumer Protection,
        Brückenstraße 6, 10179 Berlin<br /><br />
        <span class="font-weight-bold">Brandenburg</span><br />
        Ministry for Rural Development, Environment and Consumer Protection of
        the State of Brandenburg, Heinrich-Mann-Allee 103, 14473 Potsdam<br /><br />
        <span class="font-weight-bold">Mecklenburg-Western Pomerania</span
        ><br />
        State Office for the Environment, Nature Conservation and Geology
        Mecklenburg-Western Pomerania, Goldberger Straße 12, 18273 Güstrow<br /><br />
        <span class="font-weight-bold">Free State of Saxony</span><br />
        Saxon State Ministry for Environment and Agriculture, Archivstraße 1,
        01097 Dresden<br /><br />
        <span class="font-weight-bold">Saxony-Anhalt</span><br />
        Ministry of Agriculture and Environment of the State of Saxony-Anhalt,
        Olvenstedter Straße 4, 39012 Magdeburg<br /><br />
        <span class="font-weight-bold">Thuringia</span><br />
        Ministry of Agriculture, Nature Conservation and Environment,
        Beethovenstraße 3, 99096 Erfurt<br /><br />
        Any use within a commercial enterprise, as well as reproduction of any
        kind, translations, microfilming and storage and processing in
        electronic systems require the written consent of the Zentek GmbH & Co.
        KG. <br /><br />
        Although we take great care to offer you quality links, we hereby
        declare that we assume no responsibility for the contents of the linked
        pages and do not make these our own. This statement applies to all links
        placed on this website. In no case are we responsible for the content of
        Internet sites that are linked to us. We are also unable to determine
        references to us or to check those. Zentek GmbH & Co. KG further has no
        effect on how the information provided here is used, distributed or
        forwarded in a changed manner. Zentek GmbH & Co. KG can therefore not be
        liable for damages, lost benefits or other direct or indirect
        consequences arising from the use of the information offered here.
      </p>
    </v-card-text>
  </v-sheet>
</template>
<script lang="ts">
import Vue from "vue";
export default Vue.extend({
  data: () => {
    return {};
  },
});
</script>
<style lang="sass" scoped>
.ln-1
  line-height: 1.5rem
.label-left
  display: inline-block
  width: 150px
.imprint
  width: 70%
  max-width: 1600px !important
  margin: auto
</style>
