<template>
  <v-card class="px-12 rounded-lg">
    <v-icon
      @click="$emit('close-this')"
      style="
        color: black;
        position: absolute !important;
        right: 16px !important;
        font-size: 2.5rem;
        margin-top: 16px !important;
      "
      >mdi-close-circle-outline</v-icon
    >
    <v-card-title
      class="display-1 font-weight-bold mt-8 pb-8 text-left text-uppercase"
    >
      {{ $t("atomComponentLegalNotice.title") }}
    </v-card-title>
    <v-card-text class="text-justify black--text ln-1 mb-4">
      <p>
        {{ $t("atomComponentLegalNotice.paragraph1") }}
      </p>
      <p class="mt-8">
        {{ $t("atomComponentLegalNotice.paragraph2") }}
      </p>
      <p class="mt-8">
        {{ $t("atomComponentLegalNotice.paragraph3") }}<br />
        <br />{{ $t("atomComponentLegalNotice.paragraph4") }}
        <a href="presse@zentek.de">presse@zentek.de</a
        >{{ $t("atomComponentLegalNotice.paragraph5") }}
      </p>
      <p class="mt-8">
        {{ $t("atomComponentLegalNotice.paragraph6") }}
      </p>
      <p class="mt-8">
        {{ $t("atomComponentLegalNotice.paragraph7") }}
      </p>
      <p class="mt-8">
        {{ $t("atomComponentLegalNotice.paragraph8") }}
      </p>
      <p class="mt-8">
        {{ $t("atomComponentLegalNotice.paragraph9") }}
      </p>
      <p class="mt-8">
        {{ $t("atomComponentLegalNotice.paragraph10") }}
      </p>
      <p class="mt-8">
        {{ $t("atomComponentLegalNotice.paragraph11") }}
      </p>
      <p class="mt-8">
        {{ $t("atomComponentLegalNotice.paragraph12") }}
      </p>
    </v-card-text>
  </v-card>
</template>
<script lang="ts">
import Vue from "vue";
export default Vue.extend({
  data: () => {
    return {};
  },
});
</script>
<style lang="sass" scoped>
.ln-1
  line-height: 1.5rem
.label-left
  display: inline-block
  width: 150px
</style>
