<template>
  <v-card class="px-12 rounded-lg">
    <v-icon
      @click="$emit('close-this')"
      style="
        color: black;
        position: absolute !important;
        right: 16px !important;
        font-size: 2.5rem;
        margin-top: 16px !important;
      "
      >mdi-close-circle-outline</v-icon
    >
    <v-card-title
      class="display-1 font-weight-bold mt-8 pb-8 text-left text-uppercase"
    >
      {{ $t("atomComponentDataPrivacy.dataPrivacy") }}
    </v-card-title>
    <v-card-text class="text-left black--text mb-10">
      <p>{{ $t("atomComponentDataPrivacy.update") }}</p>
      <p class="mt-8 font-weight-bold">
        {{ $t("atomComponentDataPrivacy.general") }}
      </p>
      <p>
        {{ $t("atomComponentDataPrivacy.generalTextPart1") }}
        <a
          href="https://mengenmeldung.dualessystemzentek.de/"
          target="_blank"
          class="no-deco"
        >
          <span> www.mengenmeldung.dualessystemzentek.de</span>
        </a>
        {{ $t("atomComponentDataPrivacy.generalTextPart2") }}
      </p>
      <p>
        {{ $t("atomComponentDataPrivacy.generalTextPart3") }}
      </p>
      <p>
        {{ $t("atomComponentDataPrivacy.generalTextPart4") }}
        <br />
        {{ $t("atomComponentDataPrivacy.generalTextPart5") }}
      </p>
      <p class="mt-8 font-weight-bold">
        {{ $t("atomComponentDataPrivacy.personalData") }}
      </p>
      <p>
        {{ $t("atomComponentDataPrivacy.personalDataTextPart1") }}
      </p>
      <p>
        {{ $t("atomComponentDataPrivacy.personalDataTextPart2") }}
      </p>
      <p class="mt-8 font-weight-bold">
        {{ $t("atomComponentDataPrivacy.autoDataProcessing") }}
      </p>
      <p>
        {{ $t("atomComponentDataPrivacy.autoDataProcessingTextPart1") }}
      </p>
      <p>
        {{ $t("atomComponentDataPrivacy.autoDataProcessingTextPart2") }}
      </p>
      <p class="mt-8 font-weight-bold">
        {{ $t("atomComponentDataPrivacy.useOfYourInfo") }}
      </p>
      <p>
        {{ $t("atomComponentDataPrivacy.useOfYourInfoTextPart1") }}
      </p>
      <div>
        &emsp; 1. {{ $t("atomComponentDataPrivacy.useOfYourInfoListPart1") }}
        <br />
        &emsp; 2. {{ $t("atomComponentDataPrivacy.useOfYourInfoListPart2")
        }}<br />
        &emsp; 3. {{ $t("atomComponentDataPrivacy.useOfYourInfoListPart3")
        }}<br />
        &emsp; 4. {{ $t("atomComponentDataPrivacy.useOfYourInfoListPart4")
        }}<br />
        &emsp; 5. {{ $t("atomComponentDataPrivacy.useOfYourInfoListPart5")
        }}<br />
        &emsp; 6. {{ $t("atomComponentDataPrivacy.useOfYourInfoListPart6")
        }}<br />
        &emsp;{{ $t("atomComponentDataPrivacy.useOfYourInfoListPart7") }}<br />
      </div>
      <br />
      <p>
        {{ $t("atomComponentDataPrivacy.useOfYourInfoTextPart2") }}
      </p>
      <p>
        {{ $t("atomComponentDataPrivacy.useOfYourInfoTextPart3") }}
      </p>
      <p class="mt-8 font-weight-bold">
        {{ $t("atomComponentDataPrivacy.disseminationOfData") }}
      </p>
      <p>
        {{ $t("atomComponentDataPrivacy.disseminationOfDataTextPart1") }}
      </p>
      <p>
        {{ $t("atomComponentDataPrivacy.disseminationOfDataTextPart2") }}
      </p>
      <p>
        {{ $t("atomComponentDataPrivacy.disseminationOfDataTextPart3") }}
      </p>
      <p class="mt-8 font-weight-bold">
        {{ $t("atomComponentDataPrivacy.storagePeriod") }}
      </p>
      <p>
        {{ $t("atomComponentDataPrivacy.storagePeriodText") }}
      </p>
      <p class="mt-8 font-weight-bold">
        {{ $t("atomComponentDataPrivacy.creationOfProfiles") }}
      </p>
      <p>
        {{ $t("atomComponentDataPrivacy.creationOfProfilesText") }}
      </p>
      <p class="mt-8 font-weight-bold">
        {{ $t("atomComponentDataPrivacy.dataProtectionRights") }}
      </p>
      <p>
        {{ $t("atomComponentDataPrivacy.dataProtectionRightsTextPart1") }}
      </p>
      <p>
        {{ $t("atomComponentDataPrivacy.dataProtectionRightsTextPart2") }}
      </p>
      <p>
        {{ $t("atomComponentDataPrivacy.dataProtectionRightsTextPart3") }}
      </p>
      <p>
        {{ $t("atomComponentDataPrivacy.dataProtectionRightsTextPart4") }}
      </p>

      <p class="mt-8 font-weight-bold">
        {{ $t("atomComponentDataPrivacy.noteOnGoogleAnalytics") }}
      </p>
      <p>
        {{ $t("atomComponentDataPrivacy.noteOnGoogleAnalyticsTextPart1") }}
        <a
          href="https://tools.google.com/dlpage/gaoptout"
          target="_blank"
          class="no-deco"
          >tools.google.com/dlpage/gaoptout</a
        >.
      </p>
      <p>
        {{ $t("atomComponentDataPrivacy.noteOnGoogleAnalyticsTextPart2") }}
      </p>
      <p class="mt-8 font-weight-bold">
        {{ $t("atomComponentDataPrivacy.noteOnSocialNetworks") }}
      </p>
      <p>
        {{ $t("atomComponentDataPrivacy.noteOnSocialNetworksTextPart1") }}
      </p>
      <p>
        {{ $t("atomComponentDataPrivacy.noteOnSocialNetworksTextPart2")
        }}<a
          href="https://www.facebook.com/legal/controller_addendum"
          target="_blank"
          class="no-deco"
          >https://www.facebook.com/legal/controller_addendum</a
        >{{ $t("atomComponentDataPrivacy.noteOnSocialNetworksTextPart3") }}
      </p>
      <p>
        {{ $t("atomComponentDataPrivacy.noteOnSocialNetworksTextPart4") }}
        <a
          href="https://www.facebook.com/about/privacy"
          target="_blank"
          class="no-deco"
          >https://www.facebook.com/about/privacy</a
        >{{ $t("atomComponentDataPrivacy.noteOnSocialNetworksTextPart5") }}
      </p>
    </v-card-text>
  </v-card>
</template>
<script lang="ts">
import Vue from "vue";
import { mapState } from "vuex";
export default Vue.extend({
  data: () => {
    return {};
  },
});
</script>
