<template>
  <v-container class="table-container ma-0 pa-0 pt-16 text-center">
    <v-card class="mx-0 px-0 pb-1" flat>
      <v-card-title class="headline font-weight-bold px-0 mx-0 pb-6"
        ><v-icon color="black" class="pl-0 pr-4" size="60px"
          >$vuetify.icons.three</v-icon
        >
        {{ $t("signature.title") }}
      </v-card-title>

      <p style="word-spacing: normal" class="text-left">
        {{ $t("signature.paragraph") }}
      </p>
      <v-row
        style="width: 718px; margin: 0 auto; max-width: 70vw !important"
        class="pt-5 pb-0"
      >
        <v-col class="pl-0">
          <v-card-text
            class="py-1 px-0 text-md-body-1 font-weight-bold text-left"
          >
            {{ $t("signature.firstName") }}
          </v-card-text>
          <v-text-field
            v-model="firstName"
            :placeholder="$t('feedbackLabel.firstName')"
            outlined
            dense
            class="mt-0 signature-field"
            @input="sendFirstName"
            :rules="[(v) => !!v || $t('signature.firstNamePlaceholder')]"
          ></v-text-field>
        </v-col>
        <v-col class="pl-16">
          <v-card-text
            class="py-1 px-0 text-md-body-1 font-weight-bold text-left"
          >
            {{ $t("signature.lastName") }}
          </v-card-text>
          <v-text-field
            v-model="lastName"
            :placeholder="$t('feedbackLabel.lastName')"
            outlined
            dense
            class="mt-0 signature-field"
            @input="sendLastName"
            :rules="[(v) => !!v || $t('signature.lastNamePlaceholder')]"
          ></v-text-field>
        </v-col>
      </v-row>
      <p
        class="px-0 py-0 text-md-body-1 font-weight-bold text-left"
        style="width: 718px; margin: 0 auto; color: #000"
      >
        <span>{{ $t("signature.signature") }}</span>
        <v-tooltip right color="accent" class="info-tooltip">
          <template v-slot:activator="{ on, attrs }">
            <v-btn tabindex="1" icon v-bind="attrs" v-on="on" class="info-icon">
              <v-icon color="#000000" class="px-0" size="18px"
                >mdi-information
              </v-icon>
            </v-btn>
          </template>
          <span
            class="markertip"
            :style="'border-color: #303030 transparent transparent transparent'"
          ></span>
          <span
            class="d-block pa-0 ma-0"
            style="color: #ffffff; max-width: 190px"
            >{{ $t("signature.hint") }}</span
          >
        </v-tooltip>
      </p>
    </v-card>
    <v-card
      class="mx-0 mt-0 px-4 py-4"
      width="718"
      height="152"
      style="
        margin: 0 auto !important;
        border-radius: 10px;
        border: #b8b8b8d0 2px solid;
      "
      flat
      color="#e4fcf1"
    >
      <div
        class="signature-form pa-0"
        @mouseover="hideBackground"
        @mouseleave="checkStatus"
        @touchstart="hideBackground"
        @touchend="checkStatus"
      >
        <div class="upload-image-container" v-if="useUpload">
          <v-img
            :src="signatureData"
            max-height="75"
            max-width="580"
            class="upload-image"
          ></v-img>
        </div>
        <VueSignaturePad
          width="600px"
          height="85px"
          ref="signaturePad"
          scaleToDevicePixelRatio
          :class="{ 'the-pad': true, gone: hovered }"
          v-if="!useUpload"
          :options="{ penColor: '#00f' }"
        />
      </div>
      <v-file-input
        ref="fileOpener"
        :accept="mimes"
        hide-input
        class="desktop-sign-upload"
        v-model="file"
        @change="handleFileInput"
      >
      </v-file-input>
      <v-card-actions class="mt-2">
        <v-btn
          @click="triggerUpload"
          text
          class="signature-button nobox px-0"
          :title="$t('signature.maxSize')"
          ><v-icon size="18px" class="mr-2"> $vuetify.icons.upload </v-icon
          >{{ $t("signature.uploadLabel") }}</v-btn
        >
        <v-spacer />
        <v-btn
          @click="undo"
          color="#ffc061"
          class="signature-button pa-0 trash"
          depressed
          :title="$t('signature.undo')"
          ><v-icon size="18px" class="ma-0 pa-0"> mdi-undo </v-icon></v-btn
        >
        <v-btn
          @click="clearAll"
          depressed
          color="error"
          class="signature-button pa-0 trash"
          :title="$t('signature.clear')"
          ><v-icon size="20px" class="ma-0 pa-0">
            mdi-trash-can-outline
          </v-icon></v-btn
        >
      </v-card-actions>
    </v-card>
  </v-container>
</template>
<script>
import Vue from "vue";
import { VueSignaturePad } from "vue-signature-pad";
export default Vue.extend({
  name: "SignatureForm",
  components: { VueSignaturePad },
  data: () => {
    return {
      hovered: false,
      signatureEmpty: true,
      signatureData: null,
      useUpload: false,
      file: null,
      mimes: "image/png,image/jpeg,image/jpg",
      firstName: null,
      lastName: null,
    };
  },
  methods: {
    undo() {
      if (this.signatureEmpty === true) return;
      if (this.useUpload) {
        this.useUpload = false;
        this.signatureData = null;
        this.hovered = false;
        return;
      }
      this.$refs.signaturePad.undoSignature();
    },
    clearAll() {
      if (!this.useUpload) {
        this.$refs.signaturePad.clearSignature();
      }
      this.signatureEmpty = true;
      this.useUpload = false;
      this.signatureData = null;
      this.hovered = false;
    },
    hideBackground() {
      this.hovered = true;
    },
    checkStatus() {
      if (this.useUpload === false) {
        const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
        this.signatureEmpty = isEmpty;
        this.signatureData = data;
      }
      if (this.signatureEmpty === true) {
        this.hovered = false;
      }
    },
    triggerUpload() {
      this.$refs.fileOpener.$el.firstElementChild.firstElementChild.firstChild.click();
    },
    handleFileInput() {
      if (this.file.size > 1000000) {
        this.$toast.error(this.$t("signature.fileSizeError"));
        return false;
      }
      const reader = new FileReader();
      reader.onload = (e) => {
        this.useUpload = true;
        this.hovered = true;
        this.signatureEmpty = false;

        const dataBase64 = e.target.result.toString("base64").split(",")[1];
        const raw = atob(dataBase64);
        let result = "";
        for (let i = 0; i < raw.length; i++) {
          const hex = raw.charCodeAt(i).toString(16);
          result += hex.length === 2 ? hex : "0" + hex;
        }
        const buffer = result.toUpperCase();
        if (
          buffer.toString("hex", 0, 4).startsWith("FFD8FFE") ||
          buffer.toString("hex", 0, 4).startsWith("89504E47")
        ) {
          this.signatureData = e.target.result;
        } else {
          this.$toast.error(this.$t("signature.fileFormatError"));
          this.useUpload = false;
          this.hovered = false;
          this.signatureEmpty = true;
          this.file = undefined;
          return false;
        }
      };
      reader.readAsDataURL(this.file);
    },
    sendFirstName() {
      this.$emit("signData", this.completeData);
    },
    sendLastName() {
      this.$emit("signData", this.completeData);
    },
  },
  watch: {
    signatureData: function (val) {
      this.$emit("signData", this.completeData);
    },
  },
  computed: {
    completeData() {
      return {
        firstName: this.firstName,
        lastName: this.lastName,
        signature: this.signatureData,
      };
    },
  },
});
</script>
<style scoped>
.table-container {
  width: 100%;
  margin: 0 auto;
}
.signature-form {
  position: relative;
  width: 683px;
  height: 116px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: #8fedc2 3px dashed;
  border-radius: 8px;
  background-color: #ffffff;
  max-width: 70vw !important;
}
.nobox.v-btn {
  letter-spacing: 0 !important;
  text-indent: 0 !important;
  min-width: 38% !important;
}
.trash.v-btn:not(.v-btn--round).v-size--default {
  min-width: 42px !important;
}
.the-pad {
  background-image: url("https://assets.zentek.dev/mass-signal/tvp-weee-dsz/Schreibschrift_Animation.gif");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  margin-top: -20px;
  border-bottom: 1px solid #b8b8b8;
  max-width: 70vw !important;
}
.the-pad:hover,
.the-pad:focus,
.the-pad:active,
.the-pad:visited,
.the-pad:focus-visible,
.the-pad:focus-within {
  background-image: none !important;
}
.the-pad.gone {
  background-image: none !important;
}
.signature-button >>> span.v-btn__content {
  color: black;
  text-transform: none;
  font-weight: bold;
}
.signature-button.trash >>> span.v-btn__content {
  color: #ffffff;
}
.signature-button.nobox >>> span.v-btn__content {
  margin-top: -20px !important;
  justify-content: left !important;
}
.signature-button.nobox.theme--light.v-btn::before,
.signature-button.nobox.theme--light.v-btn::after,
.signature-button.nobox >>> .v-ripple__container,
.info-icon.theme--light.v-btn::before,
.info-icon.theme--light.v-btn::after,
.info-icon >>> .v-ripple__container {
  opacity: 0 !important;
  transition: none !important;
}
.info-icon {
  margin-left: -5px;
}
.desktop-sign-upload {
  visibility: hidden;
  height: 1px;
}
.upload-image-container {
  margin-top: -20px;
  width: 600px;
  height: 85px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.upload-image {
  border-bottom: 1px solid #b8b8b8;
}
.v-tooltip__content {
  padding: 10px 20px !important;
}

span.markertip {
  transform: rotate(90deg);
  content: "";
  position: absolute;
  top: 30%;
  margin-left: -18%;
  border-width: 12px;
  border-style: solid;
}

.signature-field.v-text-field--outlined:not(.v-input--is-focused) >>> fieldset {
  border-color: rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.1);
}
</style>
