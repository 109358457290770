/* eslint-disable */
import axios from "axios";
import { ApiUrl } from "@/config/ApiUrl";

export const customerModule = {
  state: () => ({
    customer: {
      name: "",
      address: {
        street: "",
        zip: "",
        city: "",
        country: "",
      },
      id: 0,
      contact: {
        email: "",
        phone: "",
      },
      customerNumber: "",
      manufacturerNumber: "",
      ustId: "",
      lucidNumber: "",
      contractStartDate: "",
      contractEndDate: "",
      materials: null as any,
    },
    allMaterials: [] as any,
    report: null as any,
    notification: {
      reportId: "",
      businessYear: 0,
      done: "",
      submitDate: "",
    },
    expired: false,
    valid: false,
    responseFromBackend: false,
    inMaintenance: false,
  }),
  mutations: {
    authSuccess(state: any, payload: any) {
      state.notification.reportId = payload.notification.reportId;
      state.notification.businessYear = payload.notification.businessYear;
      state.notification.done = payload.notification.done;
      state.notification.submitDate = payload.notification.submitDate;
      if (payload.isExpired) {
        state.customer.address.country = payload.customer.address.country;
        state.expired = true;
      } else {
        state.customer.id = payload.customer.manufacturerId;
        state.customer.name = payload.customer.name;
        state.customer.address.street = payload.customer.address.street;
        state.customer.address.zip = payload.customer.address.zip;
        state.customer.address.city = payload.customer.address.city;
        state.customer.address.country = payload.customer.address.country;
        state.customer.contact.email = payload.customer.contact.email;
        state.customer.contact.phone = payload.customer.contact.phone;
        state.customer.customerNumber = payload.customer.customerNumber;
        state.customer.manufacturerNumber = payload.customer.manufacturerNumber
          ? payload.customer.manufacturerNumber
          : payload.customer.customerNumber;
        state.customer.ustId = payload.customer.ustId;
        state.customer.lucidNumber = payload.customer.lucidNumber;
        state.customer.contractStartDate = payload.customer.contractStartDate;
        state.customer.lucidNumber = payload.customer.lucidNumber;
        state.customer.contractEndDate = payload.customer.contractEndDate && payload.customer.contractEndDate !== null && payload.customer.contractEndDate !== ""? payload.customer.contractEndDate : "-";
        state.allMaterials = payload.allMaterials;
        state.customer.materials = [];
        const refinedCustomerMaterials = [] as any;
        for (const customerMaterial of payload.customer.materials){
          for (const allMaterial of payload.allMaterials){
            if (customerMaterial.materialNumber === allMaterial.materialNumber){
              const finalObject = {
                materialNumber: customerMaterial.materialNumber,
                materialId: customerMaterial.materialId,
                materialOrder: customerMaterial.materialOrder,
                image: allMaterial.image,
              }
              refinedCustomerMaterials.push(finalObject);
            }
          }
        }
        state.customer.materials = refinedCustomerMaterials.sort(function(a: any, b: any) {
          return ( a.materialOrder - b.materialOrder);});
        state.report = payload.report;
        state.valid = true;
      }
      state.responseFromBackend = true;
    },
    maintenanceMode(state: any, payload: any) {
      state.inMaintenance = payload;
    },
  },
  actions: {
    loadCustomerData({ commit, dispatch }: any, payload: any) {
      return new Promise((resolve, reject) => {
        axios({
          url: `${ApiUrl.CUSTOMER_INFO}/${payload.reportId}/${payload.reportType}`,
          method: "GET",
        })
          .then((apiResponse: any) => {
            if (
              apiResponse.request.status === 401 ||
              apiResponse.request.status === 404 ||
              (apiResponse.response &&
                (apiResponse.response.data.status === 404 ||
                  apiResponse.response.data.statusCode === 404))
            ) {
              reject(apiResponse);
              return apiResponse;
            } else if (apiResponse.data.inMaintenance && apiResponse.data.inMaintenance === true) {
              commit("maintenanceMode", true);
              resolve(apiResponse);
            } else {
              commit("maintenanceMode", false);
              const customer = apiResponse.data;
              commit("authSuccess", customer);
              resolve(apiResponse);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  checkMaintenanceStatus({ commit, dispatch }: any, payload: any) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${ApiUrl.MAINTENANCE_CHECK}`,
        method: "GET",
      })
        .then((apiResponse: any) => {
          if (
            apiResponse.request.status === 401 ||
            apiResponse.request.status === 404 ||
            (apiResponse.response &&
              (apiResponse.response.data.status === 404 ||
                apiResponse.response.data.statusCode === 404))
          ) {
            reject(apiResponse);
            return apiResponse;
          } else if (apiResponse.data) {
            commit("maintenanceMode", apiResponse.data);
            resolve(apiResponse.data.inMaintenance);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
},
  getters: {
    customer(state: any) {
      return state.customer;
    },
    report(state: any) {
      return state.report;
    },
    notification(state: any) {
      return state.notification;
    },
    valid(state: any) {
      return state.valid;
    },
  },
};
