import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Dashboard from "../views/Dashboard.vue";
import PrivacyPolicyPage from "../components/PrivacyPolicyPage.vue";
import CopyRightEnglishPage from "../components/CopyRightEnglishPage.vue";
import CopyRightGermanPage from "../components/CopyRightGermanPage.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Dashboard",
    component: Dashboard,
  },
  {
    path: "/yearly-forecast/:param?",
    name: "YearlyForecast",
    component: Dashboard,
  },
  {
    path: "/yearly-report/:param?",
    name: "YearlyReport",
    component: Dashboard,
  },
  {
    path: "/plan-correction/:param?",
    name: "PlanCorrection",
    component: Dashboard,
  },
  {
    path: "/privacy-policy",
    name: "PrivacyPolicyPage",
    component: PrivacyPolicyPage,
  },
  {
    path: "/datenschutz",
    name: "PrivacyPolicyPage",
    component: PrivacyPolicyPage,
  },
  {
    path: "/imprint",
    name: "CopyRightEnglishPage",
    component: CopyRightEnglishPage,
  },
  {
    path: "/impressum",
    name: "CopyRightGermanPage",
    component: CopyRightGermanPage,
  },
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

export default router;
