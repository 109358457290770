<template>
  <v-container class="pa-0 pt-5 inquiry-page">
    <div class="main-card">
      <div class="navigation-part" id="inquiry"></div>
      <v-container
        class="contact-card pa-0 d-inline-flex"
        height="600"
        color="transparent"
      >
        <v-card
          class="card-left pa-0 ma-0 elevation-4"
          color="#212529"
          height="650"
          width="43%"
          dark
          raised
        >
          <div class="pt-6 card-content d-block">
            <v-card-title class="headline text-left font-weight-bold px-0">
              {{ $t("ContactForm.anyQuestions") }}
            </v-card-title>
            <v-card-text class="px-0 pb-15 text-md-body-2 big-height">
              {{ $t("ContactForm.contactUsText1") }} <br />
              {{ $t("ContactForm.contactUsText2") }} <br />
              {{ $t("ContactForm.contactUsText3") }}
            </v-card-text>
            <v-card-title class="pt-15 text-left font-weight-bold d-block px-0">
              {{ $t("ContactForm.contact") }}
            </v-card-title>
            <v-card-text class="px-0 pt-3 pb-0 text-md-body-2">{{
              $t("ContactForm.phone")
            }}</v-card-text>
            <v-card-text class="pa-0 text-md-body-2 font-weight-bold"
              >+49 2203 89 87 500</v-card-text
            >
            <v-card-text class="px-0 pt-0 text-md-body-2">{{
              $t("ContactForm.phoneContactTimingsTVP")
            }}</v-card-text>
            <v-card-text class="px-0 pb-0 text-md-body-2">E-MAIL</v-card-text>
            <v-card-text class="pa-0 text-md-body-2 font-weight-bold"
              ><a
                :href="'mailto:mengenmeldung@dualessystemzentek.de'"
                class="pa-0 white--text contact-link"
              >
                {{ "mengenmeldung@dualessystemzentek.de" }}
              </a></v-card-text
            >
          </div>
        </v-card>
        <v-card
          class="card-right pa-0 ma-0 elevation-4"
          color="#fffff"
          height="650"
          width="57%"
        >
          <v-form
            @submit.prevent="sendInquiry"
            class="pt-10 card-content right-part"
          >
            <v-col cols="12" class="d-flex py-0">
              <v-col cols="6" class="d-block pb-0">
                <v-card-text
                  class="pr-0 pl-1 pt-0 text-md-body-2 font-weight-bold"
                  >{{ $t("ContactForm.firstname") }}</v-card-text
                >
                <v-text-field
                  v-model="contactForm.firstName"
                  :label="$t('feedbackLabel.firstName')"
                  class="pl-1 contact-input"
                  solo
                  required
                  :rules="[
                    (val) =>
                      (val || '').length > 0 ||
                      $t('feedbackLabelRules.firstName'),
                  ]"
                ></v-text-field>
              </v-col>
              <v-col cols="6" class="d-block pb-0">
                <v-card-text
                  class="pr-0 pl-3 pt-0 text-md-body-2 font-weight-bold"
                  >{{ $t("ContactForm.lastname") }}</v-card-text
                >
                <v-text-field
                  v-model="contactForm.lastName"
                  :label="$t('feedbackLabel.lastName')"
                  class="contact-input pl-3"
                  solo
                  required
                  :rules="[
                    (val) =>
                      (val || '').length > 0 ||
                      $t('feedbackLabelRules.lastName'),
                  ]"
                ></v-text-field>
              </v-col>
            </v-col>
            <v-col cols="12" class="d-flex py-0">
              <v-col cols="6" class="d-block py-0">
                <v-card-text
                  class="pr-0 pl-1 pt-0 text-md-body-2 font-weight-bold"
                  >{{ $t("ContactForm.phoneNumber") }}</v-card-text
                >
                <v-text-field
                  v-model="contactForm.phone"
                  :label="$t('feedbackLabel.phone')"
                  class="pl-1 contact-input"
                  :rules="phoneRules"
                  solo
                ></v-text-field>
              </v-col>
              <v-col cols="6" class="d-block py-0">
                <v-card-text
                  class="pr-0 pl-3 pt-0 text-md-body-2 font-weight-bold"
                  >{{ $t("ContactForm.email") }}</v-card-text
                >
                <v-text-field
                  v-model="contactForm.email"
                  :label="$t('feedbackLabel.email')"
                  class="contact-input pl-3"
                  solo
                  required
                  :rules="emailRules"
                ></v-text-field>
              </v-col>
            </v-col>
            <v-col cols="12" class="d-block py-0 px-6">
              <v-card-text
                class="pr-0 pl-1 pt-0 text-md-body-2 font-weight-bold"
                >{{ $t("ContactForm.requestText") }}</v-card-text
              >
              <v-textarea
                solo
                v-model="contactForm.inquiry"
                no-resize
                rows="4"
                :counter="500"
                class="pl-1 py-0"
              ></v-textarea>
            </v-col>
            <v-checkbox v-model="privacy" class="py-0 pl-7 pr-6 my-0">
              <template v-slot:label>
                <div class="text-md-body-2">
                  {{ $t("ContactForm.readPrivacyText1") }}
                  <a @click="$emit('open-data-privacy')">{{
                    $t("ContactForm.readPrivacyText2")
                  }}</a>
                  {{ $t("ContactForm.readPrivacyText3") }}
                </div>
              </template>
            </v-checkbox>
            <v-col cols="12" class="d-block py-0 pl-7 pr-6">
              <v-btn
                color="primary"
                @click="sendInquiry"
                class="send-button"
                rounded
                depressed
                x-large
                width="100%"
                ><v-icon size="36px"> $vuetify.icons.sendButton </v-icon>
                {{ $t("ContactForm.sendRequest") }}</v-btn
              >
            </v-col>
          </v-form>
        </v-card>
      </v-container>
    </div>
  </v-container>
</template>

<script lang="ts">
import Vue from "vue";
import {
  emailRules,
  phoneRules,
  validatePhone,
  validateEmail,
} from "@/helpers";
import axios from "axios";
import { ApiUrl } from "@/config/ApiUrl";

export default Vue.extend({
  name: "Contact",
  props: {
    customerName: String,
    manufacturerNumber: String,
  },
  components: {},
  data: () => {
    return {
      emailRules: emailRules,
      phoneRules: phoneRules,
      contactForm: {
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        inquiry: "",
        asContactPerson: 0,
        customerIdentity: "",
        customerName: "",
      },
      privacy: false,
    };
  },
  methods: {
    sendInquiry() {
      if (
        this.contactForm.firstName === "" ||
        this.contactForm.lastName === ""
      ) {
        this.$toast.error(this.$t("toastTexts.firstAndLastName") as string);
        return false;
      } else if (
        this.contactForm.email === "" ||
        this.contactForm.phone === "" ||
        !validatePhone(this.contactForm.phone) ||
        !validateEmail(this.contactForm.email)
      ) {
        this.$toast.error(this.$t("toastTexts.emailAndPhone") as string);
        return false;
      } else if (this.contactForm.inquiry === "") {
        this.$toast.error(this.$t("toastTexts.stateYourRequest") as string);
        return false;
      } else if (this.privacy !== true) {
        this.$toast.error(this.$t("toastTexts.acceptPrivacyText") as string);
        return false;
      } else if (this.contactForm.inquiry.length > 500) {
        this.$toast.error(this.$t("toastTexts.maxCharsReached") as string);
        return false;
      }
      this.contactForm.customerIdentity = this.manufacturerNumber;
      this.contactForm.customerName = this.customerName;
      this.$store.dispatch("checkMaintenanceStatus").then((status) => {
        if (status !== true) {
          axios({
            url: ApiUrl.SEND_MESSAGE,
            method: "POST",
            data: this.contactForm,
          })
            .then((apiResponse: any) => {
              this.$toast(this.$t("toastTexts.requestSentSucess") as string);
              this.$emit("successfully-send-email");
            })
            .catch((error) => {
              this.$toast.error(this.$t("toastTexts.internalError") as string);
            });
        } else {
          window.location.reload();
        }
      });
    },
  },
});
</script>
<style scoped>
.inquiry-page {
  background-color: #e8fcf3;
  min-width: 100vw;
  min-height: 1000px !important;
}
.contact-card {
  width: 75%;
  margin-top: 20px;
  margin-left: 12.5%;
}
.navigation-part {
  /*this is an empty element for nice navigation look*/
  margin-top: 140px;
}
.card-left,
.card-right {
  border-radius: 10px;
}
.card-left {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px !important;
  background-image: url("../assets/Kontaktformular_Kreise.png");
  background-repeat: no-repeat;
  background-position: left bottom;
  background-size: 90% auto;
}
.card-right {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px !important;
}
.card-content {
  width: 72%;
  height: 95%;
  margin: auto;
}
.right-part {
  width: 95%;
  margin: auto;
}
.headline {
  word-break: break-word;
  font-size: 1.775rem !important;
}
.big-height {
  height: 40%;
}
.contact-link {
  text-decoration: none;
  font-weight: bold;
  text-align: left !important;
  cursor: pointer;
}
.right-part >>> .v-label,
.right-part >>> textarea {
  font-size: 0.875rem !important;
  color: rgba(0, 0, 0, 0.87);
}
.contact-input {
  width: 99%;
}
.inquiry-page >>> .v-input__control {
  min-height: 40px !important;
}
.send-button >>> span.v-btn__content {
  color: black;
  text-transform: none;
  font-weight: bold;
}
</style>
