/* eslint-disable */
import Vue from "vue";
import i18n from "./i18n";

export const EventBus = new Vue();

/* Validate Input fields functions */

export function validateEmail(email: string) {
  return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i.test(
    email
  );
}

export function validatePhone(phone: string) {
  if (phone !== "") {
    return /^((\+|00)\d\d)? ?(-|\/)? ?(\(\d+\)|\d+) ?-? ?[-/\d ]+(\d|\( ?\d+ ?\))\s?$/.test(
      phone
    );
  } else {
    return true;
  }
}

export function validateName(name: string) {
  // for international customers
  return /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]{1,30}$/.test(
    name
  );
}

export function validateGermanDecimals(germanDecimals: string) {
  return /^(([0-9]{0,9})|([0-9]{1,3}([.][0-9]{3}){1,2}))(?:[,][0-9]{1,3})?$/.test(germanDecimals);
}

export function validateEnglishDecimals(englishDecimals: string) {
  return /^(([0-9]{0,9})|([0-9]{1,3}([,][0-9]{3}){1,2}))(?:[.][0-9]{1,3})?$/.test(englishDecimals);
}

/* Validatation for input fields Rules */

export const emailRules = [
  (email: string) =>
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i.test(
      email // eslint-disable-next-line
    ) || i18n.t('feedbackLabelRules.email'), // should be in single quotes
];

export const numberRules = [
  (value: any) =>
    value === null ||
    /^[0-9]+$/.test(value) || // eslint-disable-next-line
    i18n.t('feedbackLabelRules.enterCorrectNumber'), // should be in single quotes
];

export const phoneRules = [
  (value: any) => {
    if (value) {
      return (
        /^((\+|00)\d\d)? ?(-|\/)? ?(\(\d+\)|\d+) ?-? ?[-/\d ]+(\d|\( ?\d+ ?\))\s?$/.test(
          value
        ) || i18n.t("feedbackLabelRules.enterCorrectNumber")
      );
    } else {
      return true;
    }
  }, // should be in single quotes
];

export const germanDecimalRules = [
  (value: any) =>
    value === null ||
    /^(([0-9]{0,9})|([0-9]{1,3}([.][0-9]{3}){1,2}))(?:[,][0-9]{1,3})?$/.test(value) || // eslint-disable-next-line
    i18n.t('feedbackLabelRules.enterCorrectNumber'), // should be in single quotes
];

export const englishDecimalRules = [
  (value: any) =>
    value === null ||
    /^(([0-9]{0,9})|([0-9]{1,3}([,][0-9]{3}){1,2}))(?:[.][0-9]{1,3})?$/.test(value) || // eslint-disable-next-line
    i18n.t('feedbackLabelRules.enterCorrectNumber'), // should be in single quotes
];

export function convertLocalNumberStringToFloat(
  locale: string,
  inputNumber: string
) {
  let number = 0;
  if (locale === "de-DE") {
    const neutralGermanString = inputNumber.replace(/\./g, "");
    const normalized = neutralGermanString.replace(/,/g, ".");
    number = parseFloat(normalized);
  } else {
    const normalized = inputNumber.replace(/,/g, "");
    number = parseFloat(normalized);
  }
  return number;
}

export const dachCountries = [
  "de",
  "deutschland",
  "germany",
  "at",
  "austria",
  "österreich",
  "ch",
  "switzerland",
  "schweiz",
];
