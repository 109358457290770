<template>
  <v-container class="table-container ma-0 pa-0 pt-16">
    <v-card v-if="materials" class="mx-0 px-0 pb-16" flat>
      <v-card-title class="headline font-weight-bold px-0 mx-0 pb-6"
        ><v-icon color="black" class="pl-0 pr-4" size="60px"
          >$vuetify.icons.two</v-icon
        >
        {{ $t("DashboardSectionTwoHeading.anpassungPlanmengen") }}
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="materials"
        item-key="id"
        :items-per-page="-1"
        class="elevation-4 amount-table"
        :hide-default-footer="true"
      >
        <template v-slot:body="{ items }">
          <tbody>
            <tr v-for="item in items" :key="item.materialId">
              <td class="text-md-body-1 pl-10">
                <div
                  class="mx-0 px-0"
                  style="display: flex; align-items: center"
                >
                  <div
                    class="px-0 pt-4 mx-0"
                    style="
                      margin-bottom: -10px;
                      margin-top: -6px;
                      width: 5rem;
                      height: 6rem;
                    "
                  >
                    <v-img :src="item.image" class="material-icon"></v-img>
                  </div>
                  <div
                    class="mx-0"
                    style="align-items: left; min-width: max-content"
                  >
                    {{ $t(`materials.${item.materialNumber}`) }}
                  </div>
                </div>
              </td>
              <template
                v-for="currentMaterial in currentReport.materialVolumes"
              >
                <td
                  class="text-md-body-1 text-right amount-table-border"
                  v-if="currentMaterial.materialId === item.materialId"
                  :key="currentMaterial.materialId"
                >
                  <div class="ma-0 pa-0 position-previous-value">
                    <div class="ma-0 pl-16">
                      {{
                        currentMaterial.forecastAmount &&
                        currentMaterial.forecastAmount !== null
                          ? currentMaterial.forecastAmount.toLocaleString(
                              locale,
                              {
                                minimumFractionDigits: 3,
                                maximumFractionDigits: 3,
                              }
                            )
                          : Number(0).toLocaleString(locale, {
                              minimumFractionDigits: 3,
                              maximumFractionDigits: 3,
                            })
                      }}
                      {{ currentMaterial.unit }}
                    </div>
                    <div class="ml-auto pa-0 position-tooltip">
                      <v-tooltip
                        v-if="currentMaterial.materialId === item.materialId"
                        content-class="previous-tooltip"
                        top
                      >
                        <template v-slot:activator="{ on }">
                          <v-icon
                            v-on="on"
                            @click="setCopyPrevValue(currentMaterial)"
                            class="copy-previous-value-btn"
                            >mdi-chevron-right-circle-outline</v-icon
                          >
                        </template>
                        <span>{{ $t("table.applyPreviousValue") }}</span>
                      </v-tooltip>
                    </div>
                  </div>
                </td>
              </template>
              <td class="text-md-body-1 text-center">
                <template v-for="materialAmount in materialAmounts">
                  <v-text-field
                    v-model="materialAmount.forecastCorrectionAmount"
                    class="text-md-body-1 text-right text-field-td"
                    :rules="decimalRules"
                    dense
                    outlined
                    flat
                    solo
                    suffix="kg"
                    hide-details="auto"
                    v-if="materialAmount.materialId === item.materialId"
                    :key="materialAmount.materialId"
                    :placeholder="numberPlaceholder"
                    @keypress.native="checkUserInput"
                    @focus="handleFocus"
                    @input="calculateDifference(materialAmount)"
                  ></v-text-field>
                </template>
              </td>
              <td
                style="
                  background: #e4fcf1;
                  max-width: 2rem;
                  overflow: hidden !important;
                "
                class="amount-table-border-green px-0"
              >
                <template v-for="materialAmount in materialAmounts">
                  <v-col
                    cols="12"
                    class="pr-0 pl-2 py-0 d-flex"
                    v-if="materialAmount.materialId === item.materialId"
                    :key="materialAmount.materialId"
                  >
                    <v-col
                      cols="10"
                      class="px-0 py-0 d-inline justify-center mx-0"
                      style="display: flex !important"
                    >
                      <div class="px-0 pr-2 mx-0">
                        <template v-if="materialAmount.difference > 0"
                          >+
                        </template>
                        <template v-else-if="materialAmount.difference < 0"
                          >-
                        </template>
                        <template v-else>= </template>
                      </div>
                      {{
                        Math.abs(materialAmount.difference).toLocaleString(
                          locale,
                          {
                            minimumFractionDigits: 3,
                            maximumFractionDigits: 3,
                          }
                        )
                      }}
                      {{ materialAmount.unit }}
                    </v-col>
                  </v-col>
                </template>
              </td>
              <td
                style="
                  background: #e4fcf1;
                  max-width: 2rem;
                  overflow: hidden !important;
                "
                class="px-0"
              >
                <template v-for="materialAmount in materialAmounts">
                  <v-col
                    cols="12"
                    class="pr-0 pl-2 py-0 d-flex"
                    v-if="materialAmount.materialId === item.materialId"
                    :key="materialAmount.materialId"
                  >
                    <v-col
                      cols="10"
                      class="px-0 py-0 d-inline justify-center"
                      style="display: flex !important"
                    >
                      <div class="px-0 pr-2 mx-0">
                        <template v-if="materialAmount.difference > 0"
                          >+
                        </template>
                        <template v-else-if="materialAmount.difference < 0"
                          >-
                        </template>
                        <template v-else>= </template>
                      </div>
                      {{
                        localizeSumWithNoDecimals(
                          Math.abs(materialAmount.differencePercentage)
                        )
                      }}
                      %
                    </v-col>
                  </v-col>
                </template>
              </td>
            </tr>
          </tbody>
        </template>
      </v-data-table>
      <span class="hint-text mx-0 mt-2 text-right pr-0 d-block">
        {{ $t("decimalHint") }}
      </span>
    </v-card>
    <signature-form @signData="updateSignature" :key="signatureKey" />
    <div class="button-width mx-auto my-12 px-0 py-10">
      <v-btn
        color="primary"
        class="send-button"
        rounded
        depressed
        x-large
        width="100%"
        height="65px"
        @click="checkForZero"
        :disabled="infoEditMode || !safeToSave"
        ><v-icon size="40px" class="mr-2"> $vuetify.icons.sendButton </v-icon>
        {{ $t("btnReportQuantities") }}</v-btn
      >
      <span class="hint-text hint-middle mx-auto mt-4 text-center d-block">{{
        $t("btnClickDescription")
      }}</span>
    </div>
    <loading-component :isLoading="submitClicked" />
    <v-dialog v-model="isZero" max-width="600" overlay-color="white" persistent>
      <zero-warning @submit-zero="sendData" @close-me="isZero = false" />
    </v-dialog>
  </v-container>
</template>

<script lang="ts">
import LoadingComponent from "@/components/atoms/LoadingComponent.vue";
import { ApiUrl } from "@/config/ApiUrl";
import axios from "axios";
import Vue from "vue";
import { mapState } from "vuex";
import {
  convertLocalNumberStringToFloat,
  englishDecimalRules,
  germanDecimalRules,
  validateEnglishDecimals,
  validateGermanDecimals,
} from "../helpers";
import SignatureForm from "./atoms/SignatureForm.vue";
import ZeroWarning from "./atoms/ZeroWarning.vue";

interface PlanCorrectionReportRequest {
  manufacturerId: number;
  reportId: string;
  materialVolumes: [
    {
      materialId: number;
      unit: string;
      forecastAmount: number;
    }
  ];
  businessYear: number;
  submitDate: string;
  reporter: {
    firstName: string;
    lastName: string;
    signature: string;
  };
}
export default Vue.extend({
  props: {
    infoEditMode: Boolean,
  },
  name: "PlanCorrection",
  components: {
    LoadingComponent,
    ZeroWarning,
    SignatureForm,
  },
  data: () => {
    return {
      headers: [
        /* header for plan-correction table */
        {
          text: "Verkaufsverpackung",
          align: "start",
          value: "name",
          class: "amount-table-header pl-10",
          sortable: false,
          width: "25%",
        },
        {
          text: "Planmenge 20XX",
          align: "center",
          value: "currentYearAmount",
          class: "amount-table-header",
          sortable: false,
          width: "23%",
        },
        {
          text: "Planmenge 20XX",
          align: "center",
          value: "forecastAmount",
          class: "amount-table-header",
          sortable: false,
          width: "20%",
        },
        {
          text: "Differenz kg",
          align: "center",
          value: "forecastAmount",
          class: "amount-table-header",
          sortable: false,
          width: "17%",
        },
        {
          text: "Differenz %",
          align: "center",
          value: "forecastAmount",
          class: "amount-table-header",
          sortable: false,
          width: "17%",
        },
      ],
      materialAmounts: [] as any,
      currentLanguageSetting: "",
      submitClicked: false,
      isZero: false,
      reporter: {
        firstName: "" as string,
        lastName: "" as string,
        signature: "" as string,
      },
      signatureKey: 0,
    };
  },
  watch: {
    "$i18n.locale": function (newVal, oldVal) {
      this.headers[0].text = this.$i18n.t(
        "DashboardTableHeader.salesPackaging"
      ) as string;
      this.headers[1].text = `${
        this.$i18n.t("DashboardTableHeader.prevPlanned") as string
      } ${this.businessYear}`;
      this.headers[2].text = `${
        this.$i18n.t("DashboardTableHeader.newPlanned") as string
      } ${this.businessYear}`;
      this.headers[3].text = `${
        this.$i18n.t("DashboardTableHeader.diffKg") as string
      }`;
      this.headers[4].text = `${
        this.$i18n.t("DashboardTableHeader.diffPer") as string
      }`;
      this.signatureKey++;
    },
    materialAmounts: function (newVal, oldVal) {
      for (const materialAmount of newVal) {
        for (const currentMaterial of this.currentReport.materialVolumes) {
          if (
            currentMaterial.materialId === materialAmount.materialId &&
            materialAmount.forecastCorrectionAmount === ""
          ) {
            materialAmount.difference = 0 - currentMaterial.forecastAmount;
            materialAmount.differencePercentage =
              currentMaterial.forecastAmount > 0 &&
              currentMaterial.forecastAmount !== null
                ? ((0 - currentMaterial.forecastAmount) /
                    currentMaterial.forecastAmount) *
                  100
                : 0;
          }
        }
      }
    },
  },
  computed: {
    ...mapState({
      manufacturerId: (state: any) => state.customerManagement.customer.id,
      materials: (state: any) => state.customerManagement.customer.materials,
      currentReport: (state: any) => state.customerManagement.report,
      businessYear: (state: any) =>
        state.customerManagement.notification.businessYear,
      reportId: (state: any) => state.customerManagement.notification.reportId,
    }),
    nonDangerousMaterials() {
      let nonDangerousMaterials = [] as any;
      if (this.materials !== null) {
        nonDangerousMaterials = this.materials;
      }
      return nonDangerousMaterials;
    },
    dataComplete() {
      if (
        !this.reporter.signature ||
        this.reporter.signature === null ||
        this.reporter.signature === "" ||
        this.reporter.firstName === null ||
        this.reporter.firstName.trim() === "" ||
        this.reporter.lastName === null ||
        this.reporter.lastName.trim() === ""
      )
        return false;
      return true;
    },
    locale() {
      if (this.$i18n.locale === "en") {
        return "en-GB";
      }
      return "de-DE";
    },
    numberPlaceholder() {
      if (this.$i18n.locale === "en") {
        return "0.000";
      }
      return "0,000";
    },
    decimalRules() {
      if (this.$i18n.locale === "en") {
        return englishDecimalRules;
      }
      return germanDecimalRules;
    },
    safeToSave() {
      let isValid = true;
      for (const materialAmount of this.materialAmounts) {
        const singleValid =
          this.$i18n.locale === "de"
            ? validateGermanDecimals(materialAmount.forecastCorrectionAmount)
            : validateEnglishDecimals(materialAmount.forecastCorrectionAmount);
        if (!singleValid || !this.dataComplete) {
          isValid = false;
        }
      }
      return isValid;
    },
  },
  mounted() {
    const materialAmounts = [] as any;
    for (const material of this.materials) {
      const materialAmount = {
        materialId: material.materialId,
        unit: "kg",
        forecastCorrectionAmount: "" as any,
        difference: 0 as number,
        differencePercentage: 0 as number,
      };
      materialAmounts.push(materialAmount);
    }
    this.materialAmounts = materialAmounts;
    this.headers[0].text = this.$i18n.t(
      "DashboardTableHeader.salesPackaging"
    ) as string;
    this.headers[1].text = `${
      this.$i18n.t("DashboardTableHeader.prevPlanned") as string
    } ${this.businessYear}`;
    this.headers[2].text = `${
      this.$i18n.t("DashboardTableHeader.newPlanned") as string
    } ${this.businessYear}`;
    this.headers[3].text = `${
      this.$i18n.t("DashboardTableHeader.diffKg") as string
    }`;
    this.headers[4].text = `${
      this.$i18n.t("DashboardTableHeader.diffPer") as string
    }`;
  },
  methods: {
    setCopyPrevValue(item: any) {
      for (const materialAmount of this.materialAmounts) {
        if (item.materialId === materialAmount.materialId) {
          if (item.forecastAmount && item.forecastAmount !== null) {
            materialAmount.forecastCorrectionAmount =
              item.forecastAmount.toLocaleString(this.locale, {
                minimumFractionDigits: 3,
                maximumFractionDigits: 3,
              });
          } else {
            materialAmount.forecastCorrectionAmount = Number(0).toLocaleString(
              this.locale,
              {
                minimumFractionDigits: 3,
                maximumFractionDigits: 3,
              }
            );
          }
          this.calculateDifference(materialAmount);
          break;
        }
      }
    },
    calculateDifference(materialAmount: any) {
      if (this.$i18n.locale === "en") {
        const valid = !materialAmount.forecastCorrectionAmount.includes(",,");
        if (valid) {
          const firstPart =
            materialAmount.forecastCorrectionAmount.split(".")[0];
          let secondPart = "";
          if (materialAmount.forecastCorrectionAmount.split(".").length > 1) {
            for (
              let x = 1;
              x < materialAmount.forecastCorrectionAmount.split(".").length;
              x++
            ) {
              secondPart += `.${
                materialAmount.forecastCorrectionAmount.split(".")[x]
              }`;
            }
          }
          materialAmount.forecastCorrectionAmount = `${firstPart
            .replace(/,/g, "")
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}${secondPart}`;
        }
      } else {
        const valid = !materialAmount.forecastCorrectionAmount.includes("..");
        if (valid) {
          const firstPart =
            materialAmount.forecastCorrectionAmount.split(",")[0];
          let secondPart = "";
          if (materialAmount.forecastCorrectionAmount.split(",").length > 1) {
            for (
              let x = 1;
              x < materialAmount.forecastCorrectionAmount.split(",").length;
              x++
            ) {
              secondPart += `,${
                materialAmount.forecastCorrectionAmount.split(",")[x]
              }`;
            }
          }
          materialAmount.forecastCorrectionAmount = `${firstPart
            .replace(/\./g, "")
            .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}${secondPart}`;
        }
      }

      for (let currentMaterial of this.currentReport.materialVolumes) {
        if (currentMaterial.materialId === materialAmount.materialId) {
          const difference =
            convertLocalNumberStringToFloat(
              this.locale,
              materialAmount.forecastCorrectionAmount === ""
                ? "0"
                : materialAmount.forecastCorrectionAmount
            ) -
            (currentMaterial.forecastAmount &&
            currentMaterial.forecastAmount !== null
              ? currentMaterial.forecastAmount
              : 0);

          materialAmount.difference = difference;
          if (difference === 0) {
            materialAmount.differencePercentage = 0;
            break;
          }
          materialAmount.differencePercentage =
            currentMaterial.forecastAmount > 0 &&
            currentMaterial.forecastAmount !== null
              ? (difference / currentMaterial.forecastAmount) * 100
              : 100;
          break;
        }
      }
    },
    localizeSum(value: number) {
      let stringValue = value.toLocaleString(this.locale, {
        minimumFractionDigits: 3,
        maximumFractionDigits: 3,
      });
      if (this.$i18n.locale === "en") {
        stringValue = stringValue.replace(/,/g, "");
      } else {
        stringValue = stringValue.replace(/\./g, "");
      }
      return stringValue;
    },
    localizeSumWithNoDecimals(value: number) {
      let stringValue = value.toLocaleString(this.locale, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      });
      return stringValue;
    },
    localize(value: any) {
      if (this.$i18n.locale === "en") {
        return value.replace(/,/g, "");
      } else {
        return value.replace(/\./g, "");
      }
    },
    checkUserInput($userInput: any) {
      const keyCode = $userInput.keyCode ? $userInput.keyCode : $userInput.key;

      // allow comma: 44 and dot: 46
      if (keyCode !== 44 && keyCode !== 46) {
        // allow numbers
        if (keyCode < 48 || keyCode > 57) {
          $userInput.preventDefault();
        }
      }
    },
    handleFocus() {
      if (this.infoEditMode !== false) {
        this.$vuetify.goTo("#customerInfo");
        this.$toast.error(
          this.$i18n.t(
            "toastTexts.saveOrDiscardChangesBeforeContinuing"
          ) as string
        );
      }
    },
    checkForZero() {
      let zeroAmount = true;
      for (const materialAmount of this.materialAmounts) {
        if (
          materialAmount.forecastCorrectionAmount !== "0" &&
          materialAmount.forecastCorrectionAmount !== "" &&
          materialAmount.forecastCorrectionAmount !== "0.000" &&
          materialAmount.forecastCorrectionAmount !== "0,000"
        ) {
          zeroAmount = false;
          break;
        }
      }
      if (zeroAmount !== false) {
        this.isZero = true;
      } else {
        this.sendData();
      }
    },
    updateSignature(signData: any) {
      this.reporter.signature =
        signData.signature && signData.signature !== null
          ? signData.signature
          : "";
      this.reporter.firstName =
        signData.firstName && signData.firstName !== null
          ? signData.firstName
          : "";
      this.reporter.lastName =
        signData.lastName && signData.lastName !== null
          ? signData.lastName
          : "";
    },
    sendData() {
      this.isZero = false;
      this.submitClicked = true;
      const finalMaterialAmounts = JSON.parse(
        JSON.stringify(this.materialAmounts)
      );
      for (const materialAmount of finalMaterialAmounts) {
        materialAmount.forecastCorrectionAmount =
          convertLocalNumberStringToFloat(
            this.locale,
            materialAmount.forecastCorrectionAmount === ""
              ? "0"
              : materialAmount.forecastCorrectionAmount
          );
        delete materialAmount.difference;
        delete materialAmount.differencePercentage;
      }
      const payload: PlanCorrectionReportRequest = {
        manufacturerId: this.manufacturerId,
        reportId: this.reportId,
        materialVolumes: finalMaterialAmounts,
        businessYear: this.businessYear,
        submitDate: new Date().toLocaleDateString("de-DE", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
        }),
        reporter: this.reporter,
      };
      /* AXIOS POST REQUEST HERE */
      this.$store.dispatch("checkMaintenanceStatus").then((status) => {
        if (status !== true) {
          axios({
            url: ApiUrl.PLAN_CORRECTION_SUBMIT,
            method: "POST",
            data: payload,
          })
            .then((apiResponse: any) => {
              if (apiResponse.response && apiResponse.response.data.error) {
                this.submitClicked = false;
                this.$toast.error(
                  this.$t("toastTexts.internalError") as string
                );
                return false;
              } else {
                this.submitClicked = false;
                this.$emit("successfully-submitted");
              }
            })
            .catch((error) => {
              this.submitClicked = false;
              this.$toast.error(this.$t("toastTexts.internalError") as string);
            });
        } else {
          window.location.reload();
        }
      });
    },
  },
});
</script>
<style scoped>
.table-container {
  width: 100%;
}
.text-field-td {
  width: 75%;
  margin-left: auto;
  margin-right: auto;
}
.text-field-td >>> input {
  text-align: right;
}
.text-field-td >>> .v-text-field__suffix {
  color: grey;
}
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  font-size: 1rem;
}
.text-field-td.v-text-field--outlined:not(.v-input--is-focused) >>> fieldset {
  border-color: rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.1);
}
.text-field-td >>> .v-text-field__details {
  margin-bottom: 0px;
  max-width: 100%;
  width: 100%;
  font-size: 0.3rem !important;
}
.text-field-td >>> .v-messages {
  font-size: 11px;
  height: 11px;
}
.button-width {
  width: 40%;
}
.send-button >>> span.v-btn__content {
  color: black;
  text-transform: none;
  font-weight: bold;
}
.send-button {
  border-radius: 42px;
}
.button-width .hint-middle {
  width: 80%;
}
.position-previous-value {
  display: flex;
}
.position-tooltip {
  margin-right: -30px;
}
.copy-previous-value-btn {
  color: #81ecb5 !important;
  background-color: #fff;
  border-radius: 50%;
}
tr:hover .copy-previous-value-btn {
  background: #eeeeee;
}
.copy-previous-value-btn:hover {
  background: #fff !important;
}
.previous-tooltip {
  width: 120px !important;
  padding: 4px !important;
  background-color: black !important;
  color: #fff !important;
}
.previous-tooltip {
  width: fit-content !important;
  padding: 4px !important;
  background-color: black !important;
  color: #fff !important;
}
.previous-tooltip::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}
.previous-tooltip span {
  padding-left: 2px !important;
}
</style>
